import React, { Component } from "react";

class Sidebar extends Component {
  render() {
    const path=this.props.location.pathname
    return (
        <nav className="col-md-2 d-none d-md-block bg-light sidebar border-right">
        <div className="sidebar-sticky">
            <div className="accordion" id="accordion" role="tablist" aria-multiselectable="true">
              
                <div className="card">
                    <div className={path==='/publishseries/' || path==='/publishseries_add/' || path==='/publishseries_edit/:id/'? "card-header action" :"card-header"} role="tab" id="headingTwo">
                        <h5 className="mb-0">
                            <a className="collapsed text-body d-block p-3 m-n3" data-toggle="collapse" href='/#'
                               onClick={()=>window.location.href='/publishseries/'}
                               role="button" aria-expanded="false" aria-controls="collapseTwo">
                                出版物シリーズマスタ
                            </a>
                        </h5>
                    </div>
                </div>
                <div className="card">
                    <div className={path==='/publishareas/' || path==='/publishareas_add/' || path==='/publishareas_edit/:id/'? "card-header action" :"card-header"} role="tab" id="headingThree">
                        <h5 className="mb-0">
                            <a className="collapsed text-body d-block p-3 m-n3" data-toggle="collapse" href='/#'
                               onClick={()=>window.location.href='/publishareas/'}
                               role="button" aria-expanded="false"
                               aria-controls="collapseThree">
                                出版物エリアマスタ
                            </a>
                        </h5>
                    </div>
                </div>
                <div className="card">
                    <div className={path==='/publishdatas' || path==='/publishdatas_regist' || path==='/publishdatas_add' || path==='/publishdatas_bulk_upload/' ? "card-header action" :"card-header"} role="tab" id="headinFour">
                        <h5 className="mb-0">
                            <a className="collapsed  text-body d-block p-3 m-n3" data-toggle="collapse" href='/#'
                               onClick={()=>window.location.href="/publishdatas"}
                               role="button" aria-expanded="false" aria-controls="collapseFour">
                                出版物データ登録・更新
                                </a>
                        </h5>
                    </div>
                </div>
                <div className="card">
                    <div className={path==='/recommendations' || path==='/recommendations_add' || path==='/recommendations_edit/:id/' ? "card-header action" :"card-header"} role="tab" id="headinFour">
                        <h5 className="mb-0">
                            <a className="collapsed  text-body d-block p-3 m-n3" data-toggle="collapse" href='/#'
                               onClick={()=>window.location.href="/recommendations"}
                               role="button" aria-expanded="false" aria-controls="collapseFour">
                                おすすめ本表示設定
                                </a>
                        </h5>
                    </div>
                </div>
                <div className="card">
                    <div className={path==='/onetimeurl' ? "card-header action" :"card-header"}  role="tab" id="headingFourteen">
                        <h5 className="mb-0">
                            <a className="collapsed  text-body d-block p-3 m-n3" data-toggle="collapse" href='/#'
                               onClick={()=>window.location.href='/onetimeurl'}
                               role="button" aria-expanded="false" aria-controls="collapseFourteen">
                                ワンタイムURLスキーム発行
                            </a>
                        </h5>
                    </div>
                </div>
               </div>
        </div>
    </nav>
    );
  }
}

export default Sidebar;