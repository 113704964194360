import React, { Component } from "react";
import { logoutAPI } from "../views/ApiIntegration";
import { NotificationManager } from 'react-notifications';

class TopNav extends Component {

  logout = () => {

    logoutAPI(response => {
      if (response.response.data.success === true) {
        NotificationManager.success(response.response.data.message, "success", 3000)

        alert(response.response.data.message)
        window.location.href = '/'
      }
      if (response.response.data.success === false) {
        let err = response.response.data.error
        Object.keys(err).forEach(v => {
          NotificationManager.error(err[v], v, 3000)
        });
      }
    })
  }
  render() {
    return (
      <nav className="navbar navbar-light fixed-top bg-white border-bottom flex-md-nowrap p-0 justify-content-between">
        <a className="navbar-brand head_logo" href='/#'>
          <img src={require("../assets/img/logo.png")} alt="logo" />
        </a>
        
        <div className="navbar-brand">
          <span className="user_name">
            {localStorage.getItem("nickname")}
          </span>
          <span >
            <i onClick={this.logout} className="fas fa-sign-out-alt logout_icon pointer"></i>
          </span>
        </div>
      </nav>

    );
  }
}

export default TopNav;