/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Row, } from "reactstrap";

import {
  Link,
} from 'react-router-dom';
import {
  getJapOversea,
  getJapActiveStatus
} from "../Common";

import { listSeriesAPI, deleteSeriesAPI } from "../ApiIntegration";
import { NotificationManager } from 'react-notifications';

class PublishSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataLength: "",
      editMode: false,
      selectedSeries: [],

    };
  }
  componentDidMount() {
    document.title = "出版物シリーズマスタ"
    this.listSeries();
  }
  listSeries = () => {
    listSeriesAPI(apiResponse => {
      if (apiResponse.response.data.status == true) {
        this.setState({
          data: apiResponse.response.data.data,
          dataLength: apiResponse.response.data.data.length
        });
      }
    });
  }
  selectSeries = (e) => {
    let itemArray = [];
    if (this.state.selectedSeries != "") {
      itemArray = [...this.state.selectedSeries]; // make a separate copy of the array
    } else {
      itemArray = [];
    }
    if (e.target.checked == true) {
      itemArray.push(e.target.name);

    } else {
      var index = itemArray.indexOf(e.target.name);
      itemArray.splice(index, 1);
    }

    this.setState({ selectedSeries: itemArray });
  };
  delete = (force) => {
    console.log("a")
    deleteSeriesAPI({
      id: this.state.selectedSeries,
      force: force,
    }, ({ response }) => {

      if (response.data.success == true) {
        NotificationManager.success(response.data.message, "Pulication Series Success", 3000)
        this.setState({ editMode: false })
        this.listSeries();
      }
      else {
        const linked = response.data.linked;
        if (linked) {
          document.getElementById("delete-modal-btn").click()
        } else {
          const err = response.data.error;
          Object.keys(err).forEach(v => {
            if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
          });
        }
      }
    });
  }

  render() {
    return (
      <main role="main" className="publishseries col-md-10 ml-sm-auto col-lg-10 px-4">
        <nav aria-label="パンくずリスト">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item">出版物シリーズマスタ</li>
          </ol>
        </nav>
        <div className="modal fade" id="editmodeConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="閉じる">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>書籍に紐付いたデータがあります。削除しますか？</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.delete(true)}>削除</button>
              </div>
            </div>
          </div>
        </div>

        <div className="alert alert-danger alert-dismissible fade show none" role="alert">
          保存に失敗しました。
                <button type="button" className="close" data-dismiss="alert" aria-label="閉じる">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="alert alert-success alert-dismissible fade show none" role="alert">
          保存に成功しました。
                <button type="button" className="close" data-dismiss="alert" aria-label="閉じる">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="row">
          <div className="col-8 text-danger order_text_wrap none">
            <div className="bg-light">
              レコードのドラッグ&ドロップで表示順の変更が可能です。変更を登録する場合は「保存」を押してください。
                    </div>
          </div>
          <div className="col list_btn_wrap">
            <button type="button" className="btn btn-primary add" onClick={() => window.location.href = "/publishseries_add/"}>新規登録
                        </button>
            <button type="button" className="btn btn-warning edit_mode" onClick={() => this.setState({ editMode: true })}>編集</button>
            <button type="button" className="none" id="delete-modal-btn" data-toggle="modal" data-target="#editmodeConfirm">保存</button>
            {this.state.editMode &&
              <>
                <button type="button" className="btn btn-light cancel" onClick={() => this.setState({ editMode: false })}>キャンセル</button>
                {this.state.selectedSeries.length > 0 && <button type="button" className="btn btn-danger" onClick={() => this.delete(false)}>削除</button>}
              </>}
          </div>
        </div>
        <div className="default_list_wrap mb-5">
          <table className="table default_list fixed">
            <thead className="thead-light">
              <tr>
                {this.state.editMode &&
                  <th className="w_10">削除</th>}
                <th className="w_10">シリーズコード</th>
                <th className="w_40">シリーズ名</th>
                <th className="w_15">国種</th>
                <th className="w_15">有効/無効</th>
                {/* <th className="w_15">反映状況</th> */}
              </tr>
            </thead>
            <tbody>
              {this.state.data && this.state.data.map((datas, idx) => (
                <tr key={idx}>

                  {this.state.editMode && <td>
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" onChange={this.selectSeries} className="custom-control-input  delete_check" name={datas.id} id={datas.id} />
                      <label className="custom-control-label" htmlFor={datas.id}></label>
                    </div>
                  </td>}
                  <td>{datas.series_code}</td>
                  <td><Link to={"/publishseries_edit/" + datas.id + "/"}>{datas.series_name}</Link></td>
                  <td>{getJapOversea(datas.oversea)}</td>
                  <td className="check_active">{getJapActiveStatus(datas.status)}</td>
                  {/* <td className="reflection_status">公開前</td> */}
                </tr>
              ))
                // : "Not found"
              }

            </tbody>
          </table>
        </div>
      </main>
    );
  }
}
export default PublishSeries;
