/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Row, } from "reactstrap";
// import "../../assets/css/style.css"
import {
    Link
} from 'react-router-dom';
import { uploadingBookAPI } from "../ApiIntegration"
import { NotificationManager } from 'react-notifications';


class RegisterUpdateBulkUploading extends Component {
    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props)
        this.state = {
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        this.fileArray=[]
        for (let i = 0; i < this.fileObj.length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[i][0]))
        }
        this.setState({ file: [...this.state.file, ...e.target.files] })
    }

      bulkUploading=()=>{
        uploadingBookAPI(this.state.file, response => {
          console.log("image upateeeeeeee", response);
          if (response.response.data.success == true) {
            window.location.href="/publishdatas/";
          } else {
            const err = response.data.error;
            Object.keys(err).forEach(v => {
              if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
            });
          }
        });
      }
    render() {
        return (
            <main role="main" class="col-md-10 ml-sm-auto col-lg-10 px-4">
                <nav aria-label="パンくずリスト">
                    <ol class="breadcrumb bg-white">
                        <li class="breadcrumb-item">出版物データ登録・更新</li>
                        <li class="breadcrumb-item">一括アップロード</li>
                    </ol>
                </nav>
                <div class="container rgst">
                    <div class="row justify-content-center">
                        <div class="col-8 mt-5">
                            <h5>一括アップロード</h5>
                            <form class="mt-5">
                                <div class="rgst_select">
                                    <div className="form-group multi-preview">
                                    
                                      
                                        {(this.fileArray || []).map(url => (
                                            <img src={url} alt="..." style={{ width: "150px", height: "150px", padding: "20px 20px" }} />
                                            ))}
                                    </div>

                                    <div className="form-group">
                                        <input type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple={true} />
                                    </div>
                                </div>

                                <div class="rgst_btn_wrap mt-g">
                                    <button type="button" class="btn btn-light cancel"
                                        onClick={()=>window.location.href="/publishdatas/"}>キャンセル
                                </button>

                                    <button type="button" class="btn btn-danger update" onClick={this.bulkUploading}
                                    >保存
                                </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
export default RegisterUpdateBulkUploading;