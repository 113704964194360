import React, { Suspense } from 'react';
import {
  BrowserRouter,

} from 'react-router-dom';
import Router from "./views/Router.js"
class App extends React.Component {
  render (){
    return (
    <div >
      <React.Fragment>
            <Suspense fallback={<div className="loading" />}>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </Suspense>
          </React.Fragment>
   
    </div>
  );
}
}

export default App;
