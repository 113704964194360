/* eslint-disable */
import React, { Component } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import {
  getJapOversea,
  getJapActiveStatus,
} from "../Common";

import { listPublicationAreaAPI, deleteAreaAPI, sortAreasAPI } from "../ApiIntegration"
import { NotificationManager } from 'react-notifications';

import {
  TrComponent,
  TrComponentDnD,
} from "../../Utils/DragAndDrop";

class PublishAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataLength: "",
      editMode: false,
      selectedArea: [],
    };
  }

  componentDidMount() {
    document.title = "出版物エリアマスタ"
    this.listArea();
  }
  listArea = () => {
    listPublicationAreaAPI(apiResponse => {
      if (apiResponse.response.data.status == true) {
        this.setState({
          data: apiResponse.response.data.data,
          dataLength: apiResponse.response.data.data.length
        });
      }
    });
  }

  selectArtical = (e) => {
    let itemArray = [];
    if (this.state.selectedArea != "") {
      itemArray = [...this.state.selectedArea]; // make a separate copy of the array
    } else {
      itemArray = [];
    }
    if (e.target.checked == true) {
      itemArray.push(e.target.name);

    } else {
      var index = itemArray.indexOf(e.target.name);
      itemArray.splice(index, 1);
    }
    this.setState({ selectedArea: itemArray });
  };
  delete = (force) => {
    deleteAreaAPI({
      id: this.state.selectedArea,
      force: force,
    }, ({ response }) => {

      if (response.data.success == true) {
        NotificationManager.success(response.data.message, "Pulication Area Success", 3000)
        this.setState({ editMode: false })
        this.listArea();
      }
      else {
        const linked = response.data.linked;
        if (linked) {
          document.getElementById("delete-modal-btn").click()
        } else {
          const err = response.data.error;
          Object.keys(err).forEach(v => {
            if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
          });
        }
      }
    });
  }
  onDrop = (item, newId) => {
    const id = this.state.data[item.id].id;
    const pos = newId;
    this.sortDnD(id, pos);
  };
  sortDnD = (id, pos) => {
    sortAreasAPI({ id: id, pos: pos }, (response) => {
      if (response.response.data.success == true) {
        this.listArea();
      } else {
        const err = response.data.error;
        Object.keys(err).forEach((v) => {
          if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
        });
      }
    });
  };
  render() {
    const { editMode } = this.state;
    return (
      <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
        <nav aria-label="パンくずリスト">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item">出版物エリアマスタ</li>
          </ol>
        </nav>
        <div className="modal fade" id="editmodeConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="閉じる">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>書籍に紐付いたデータがあります。削除しますか？</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.delete(true)}>削除</button>
              </div>
            </div>
          </div>
        </div>

        <div className="alert alert-danger alert-dismissible fade show none" role="alert">
          保存に失敗しました。
                    <button type="button" className="close" data-dismiss="alert" aria-label="閉じる">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="alert alert-success alert-dismissible fade show none" role="alert">
          保存に成功しました。
                    <button type="button" className="close" data-dismiss="alert" aria-label="閉じる">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="row">
          <div className="col-8 text-danger order_text_wrap none">
            <div className="bg-light">
              レコードのドラッグ&ドロップで表示順の変更が可能です。変更を登録する場合は「保存」を押してください。
                        </div>
          </div>
          <div className="col list_btn_wrap">
            <button type="button" className="btn btn-primary add" onClick={() => window.location.href = "/publishareas_add"}>
              新規登録
            </button>
            <button type="button" className="btn btn-warning edit_mode" onClick={() => this.setState({ editMode: true })}>編集</button>
            <button type="button" className="none" id="delete-modal-btn" data-toggle="modal" data-target="#editmodeConfirm">保存</button>
            {this.state.editMode && <>
              <button type="button"
                className="btn btn-light cancel"
                onClick={() => this.setState({ editMode: false })}>キャンセル
                            </button>
              {this.state.selectedArea.length > 0 &&
                <button type="button" className="btn btn-danger" onClick={() => this.delete(false)}>削除
                            </button>}
            </>}
          </div>
        </div>

        <div className="default_list_wrap mb-5">
          <DndProvider backend={HTML5Backend}>
            <ReactTable
              TrComponent={({ children, className, ...rest }) => {
                if (editMode)
                  return (
                    <TrComponentDnD
                      children={children}
                      className={className}
                      onDrop={this.onDrop}
                      {...rest}
                    />
                  );
                else
                  return (
                    <TrComponent
                      children={children}
                      className={className}
                      {...rest}
                    />
                  );
              }}
              data={this.state.data}
              showPagination={false}
              sortable={false}
              minRows={2}
              className="table thead th"
              columns={[
                {
                  Header: "削除",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "id",
                  width: 60,
                  show: this.state.editMode ? true : false,
                  Cell: (props) => (
                    <p className="list_img table.default_list td">
                      <input
                        type="checkbox"
                        onChange={this.selectArtical}
                        name={props.value}
                        id={props.value}
                        checked={
                          this.state.selectedArea.indexOf(
                            props.value.toString()
                          ) > -1
                        }
                      />
                    </p>
                  ),
                },
                {
                  Header: "エリアコード",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "area_code",
                  width: 100,
                  Cell: (props) => (
                    <p className="list_img table.default_list td">
                      {props.value ? props.value : ""}
                    </p>
                  ),
                },
                {
                  Header: "エリア名",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "area_name",
                  Cell: (props) => (
                    <Link to={`/publishareas_edit/${props.original.id}/`} >
                      <p className="table.default_list td text-wrap">
                        {props.value ? props.value : ""}
                      </p>
                    </Link>
                  ),
                },
                {
                  Header: "国種",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "oversea",
                  Cell: (props) => (
                    <p className="list_img table.default_list td">
                      {props.value ? getJapOversea(props.value) : ""}
                    </p>
                  ),
                },
                {
                  Header: "有効/無効",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "status",
                  Cell: (props) => (
                    <p className="list_img table.default_list td">
                      {props.value ? getJapActiveStatus(props.value) : ""}
                    </p>
                  ),
                },
              ]}
            />
          </DndProvider>
        </div>
      </main>
    );
  }
}
export default PublishAreas;
