/* eslint-disable */
import React, { Component } from "react";
import {loginAPI } from "../ApiIntegration"
// React Notification
import {NotificationManager} from 'react-notifications';

class IndexCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
          username: '',
          password: '',
          version: '',
        }
      }
    
    handlechange=e=>{
        this.setState({[e.target.name]:e.target.value})
    }
    componentDidMount(){
        document.title = "Signin"
        fetch('version.txt')
        .then((r) => r.text())
        .then(text  => {
          this.setState({version: text})
        })  
      }
      login=(e)=>{
        event.preventDefault();
        const {username, password} =this.state;

        loginAPI(username, password, response => {
            console.log("jjjjjjjjjjjjj",response)
            if (response.response.data.credential == false ) { 
              
              NotificationManager.error(response.response.data.message, "Error",4000);       
              }
            if (response.response.data.success == false) {       
              let err = response.response.data.error
            
              Object.keys(err).forEach(v => {
                if(err[v]){
                NotificationManager.error(err[v], v,4000);
                }
             
              });
            }
          
            if (response.response.data.credential == true) {
              NotificationManager.success(response.response.data.message, "Suuccess",4000);       

              window.location.href = '/publishdatas'
            }
          })
      }
    
  render() {
    return (
        <div className="signin_wrap">

            <div className="signin_box img">
                <img src={require("../../assets/img/logo.png")} />
            </div>
            <div className="signin_box version">
                v.{this.state.version}
            </div>
            <div className="signin_box header bg-light border">
            電子書籍管理
              
            </div>
            <div className="signin_box items border">
                <form onSubmit={this.login}>
                    <div className="form-group">
                        <label htmlFor="SigninId">ID</label>
                        <input type="text" className="form-control" name="username" onChange={this.handlechange} aria-describedby="emailHelp" placeholder="IDを入力" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="SigninPassword">Password</label>
                        <input type="password" className="form-control" name="password"  onChange={this.handlechange} placeholder="パスワードを入力" />
                    </div>
                    <div className="center">
                        {/* <Link to="/publishdatas"> */}
                    <input className="btn submit" type="submit" value="ログイン" style={{backgroundColor: "#F79646", color: "#ffffff"}} />
                    {/* </Link> */}
                    </div>
                </form>
            </div>
    </div>
    );
  }
}
export default IndexCard;