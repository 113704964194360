export const getJapOversea = (status) => {
    switch (status) {
      case "Overseas":
        return "海外";
      case "Domestic":
        return "国内";
      default:
        return "";
    }
  };
  
  export const getJapActiveStatus = (status) => {
    switch (status) {
      case "Effectiveness":
        return "有効";
      case "Invalid":
        return "無効";
  
      default:
        return "";
    }
  };
  
  export const getJapReflectionStatus = (status) => {
    switch (status) {
      case "Unpublished":
        return "公開前";
      case "Editing":
      case "While editing":
        return "編集中";
      case "Release":
        return "公開";
  
      default:
        return "";
    }
  };
