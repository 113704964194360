/* eslint-disable */
import React, { Component, Fragment } from "react";
import {
    Link
} from 'react-router-dom';
import { listPublicationAreaAPI, listSeriesAPI, addPublicationDataAPI } from "../ApiIntegration"
import { NotificationManager } from 'react-notifications';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

registerLocale("ja", ja);

class RegisterUpdatePublicationAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            isbn: "",
            uuid: uuidv4(),
            bookName: "",
            issuedDate: moment().format("YYYY-MM-DD"),
            releaseDate: moment().format("YYYY-MM-DD"),
            countryType: "0",
            area: "",
            series: "",
            bookType: "QR本・販売本",
            paperVersion: "",
            ios: "",
            android: "",
            expStartDate: moment().format("YYYY-MM-DD"),
            expEndDate: moment().format("YYYY-MM-DD"),
            period: null,
            discountRate: "",
            url: "",
            bookDesc: "",
            credit: "",
            areaData: [],
            seriesData: [],
            epubCover: "",
            subscription_plan: "0",
            file: null,
        };
    }
    componentDidMount() {
        document.title = "出版物データ登録・更新"

        listPublicationAreaAPI(apiResponse => {
            if (apiResponse.response.data.status == true) {
                this.setState({
                    areaData: apiResponse.response.data.data,
                });
            }
        });
        listSeriesAPI(apiResponse => {
            if (apiResponse.response.data.status == true) {
                this.setState({
                    seriesData: apiResponse.response.data.data,
                });
            }
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleIssuedDate = date => {
        let _date = moment(date)
        this.setState({
            issuedDate: _date.isValid() ? _date.format("YYYY-MM-DD") : null
        });
    }
    handleRealeaseDate = date => {
        let _date = moment(date)
        this.setState({
            releaseDate: _date.isValid() ? _date.format("YYYY-MM-DD") : null
        });
    }

    handleExpStartDate = date => {
        let _date = moment(date)
        this.setState({
            expStartDate: _date.isValid() ? _date.format("YYYY-MM-DD") : null
        });
    }
    handleExpEndDate = date => {
        let _date = moment(date)
        this.setState({
            expEndDate: _date.isValid() ? _date.format("YYYY-MM-DD") : null
        });
    }
    handleChangeImage = e => {
        let file = e.target.files[0];
        var url = URL.createObjectURL(file)
        this.setState({ file, epubCover: url })
    }

    savePublicationData = (epub) => {
        const { isbn, uuid, bookName, issuedDate, releaseDate, countryType, area, series, bookType,
            paperVersion, ios, android, expStartDate, expEndDate, period, discountRate, url, bookDesc, credit, file, subscription_plan } = this.state
        addPublicationDataAPI({
            isbn_edition: isbn, uuid: uuid, title: bookName, issued_date: issuedDate, release_date: releaseDate, expiration_start: expStartDate,
            expiration_end: expEndDate, oversea: countryType, series_code: series, area_code: area, book_type: bookType, paper_version: paperVersion,
            item_code_android: android, item_code_ios: ios, free_url: url, map_credit: credit, expire_days: period, discount_rate: discountRate, explanation: bookDesc,
            file: file, convert_epub: epub, subscription_plan
        }, ({ response }) => {
            if (response.data.success == true) {
                this.setState({ id: response.data.data[0].id })
                NotificationManager.success(1, response.data.message, "Signup Success")
                if (epub) {
                    document.getElementById("browsModal").style.display = "block";
                    document.getElementById("browsModal").style.opacity = "1";
                } else {
                    window.location.href = "/publishdatas/";
                }
            }
            else {
                const err = response.data.error;
                Object.keys(err).forEach(v => {
                    if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
                });
            }
        });
    }
    handleChangeDateRaw = e => {
        let value = e.target.value;
        if (value.length === 8) {
            let date = value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
            let _date = moment(date)
            this.setState({
                [e.target.name]: _date.isValid() ? _date.format("YYYY-MM-DD") : null
            })
        }
    }
    render() {
        return (
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
                <nav aria-label="パンくずリスト">
                    <ol className="breadcrumb bg-white">
                        <li className="breadcrumb-item">出版物データ登録・更新</li>
                        <li className="breadcrumb-item">新規登録</li>
                    </ol>
                </nav>
                <div className="modal fade" id="attractModal" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalCenteredScrollableTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal_large" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenteredScrollableTitle">代表観光地コード登録</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="閉じる">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form className="list_search">
                                    <div className="form-row justify-content-center">
                                        <div className="form-group col-8">
                                            <label>観光地・国・都道府県のコード又は名称</label>
                                            <input type="text" className="form-control mr-2"
                                                placeholder="観光地・国・都道府県のコード又は名称を入力" id="search_name" />
                                        </div>
                                        <div className="col-3 text-center pt-3">
                                            <button type="button" className="btn btn-secondary book_search_btn">検索
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="default_list_wrap modal_large_search ">
                                    <table className="table default_list modal_table_list attract_search_list">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="w_15">コード</th>
                                                <th className="w_15">名称</th>
                                                <th className="w_5"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">閉じる</button>
                                <button type="button" className="btn btn-danger" data-dismiss="modal">追加する</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="confirmModal" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="閉じる">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>観光地コード「<span className="area_code"></span>:<span className="area_name"></span>」を削除しますか？</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">いいえ</button>
                                <button type="button" className="btn btn-primary code_delete_yes" data-dismiss="modal">はい
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="convertModal" data-backdrop="static" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                            </div>
                            <div className="modal-body">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="mb-4">出版物データ一覧へ戻りますか？
                                        <button type="button" className="btn btn-primary" data-dismiss="modal"
                                            onClick={() => this.savePublicationData(false)}>はい
                                        </button>
                                    </div>
                                    <div className="mb-4">
                                        epubデータを変換しますか？
                                        <button type="button" className="btn btn-primary" data-toggle="modal"
                                            data-target="#convertModal" onClick={() => this.savePublicationData(true)}>はい
                                        </button>
                                    </div>


                                </div>
                                <div className="modal-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="browsModal" data-backdrop="static" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                            </div>
                            <div className="modal-body">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="mb-4">出版物データ一覧へ戻りますか？
                                        <Link to="/publishdatas">
                                            <button type="button" className="btn btn-primary" data-dismiss="modal"
                                            >はい
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="mb-3">
                                        試し読み設定へ進みますか？

                                        <button onClick={() => window.location.href = "/publishdatas_brows?id=" + this.state.id} type="button" className="btn btn-primary" data-dismiss="modal"
                                        >はい
                                        </button>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <form className="recode_edit_form publishdata_form">
                    <div className="form-group row">
                        <div className="col-4">
                            <div className="row">
                                <div className="onetime_img">
                                    {this.state.epubCover ?
                                        <img src={this.state.epubCover} />
                                        :
                                        <img src={require("../../assets/img/sample3.png")} />
                                    }
                                </div>
                                <input onChange={this.handleChangeImage} type="file" class="custom-file-input none" id="epub-cover" />
                                <label class="upload_img_btn btn" for="epub-cover">画像アップロード</label>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="form-group row">
                                <div className="col-6">
                                    <label>ISBN版刷</label><span className="required">※</span>
                                    <input type="text" className="form-control" name="isbn" value={this.state.isbn} onChange={this.handleChange} placeholder="ISBN版刷" />
                                </div>
                                <div className="col-12">
                                    <span className="form-text text-muted">13桁数値-1or2桁数値（0埋め無し）-1or2桁数値（0埋め無し）の形式で入力してください</span>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-4">
                                    <label>uuid</label>
                                    <input type="text" className="form-control" name="uuid" value={this.state.uuid} onChange={this.handleChange} placeholder="uuid" />
                                </div>
                                <div className="col-8">
                                    <label>書籍名</label><span className="required">※</span>
                                    <input type="text" className="form-control" name="bookName" value={this.state.bookName} onChange={this.handleChange} placeholder="書籍名" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-4">
                                    <label>発行日</label><span className="required">※</span>
                                    <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        placeholder="発行日"
                                        name="issuedDate"
                                        selected={this.state.issuedDate ? new Date(this.state.issuedDate) : null}
                                        onChange={this.handleIssuedDate}
                                        onChangeRaw={this.handleChangeDateRaw}
                                        locale='ja'
                                    />
                                </div>
                                <div className="col-4">
                                    <label>発売日</label><span className="required">※</span>
                                    <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        placeholder="発売日"
                                        name="releaseDate"
                                        selected={this.state.releaseDate ? new Date(this.state.releaseDate) : null}
                                        onChange={this.handleRealeaseDate}
                                        onChangeRaw={this.handleChangeDateRaw}
                                        locale='ja'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-row mt-5">
                        <div className="col-3">
                            <p className="label">国種<span className="required">※</span></p>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="countryType" value="0" checked={this.state.countryType == "0" ? "Checked" : ""} onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline1">国内</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="countryType" value="1" checked={this.state.countryType == "1" ? "Checked" : ""} onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline2">海外</label>
                            </div>
                        </div>

                        <div className="col-4">
                            <label htmlFor="area">エリア</label>
                            <select className="form-control custom-select" id="area" name="area" value={this.state.area} onChange={this.handleChange}>
                                <option>未選択</option>
                                {this.state.areaData.map((datas, idx) => (
                                    <option value={datas.id}>{datas.area_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <label htmlFor="series">シリーズ</label><span className="required">※</span>
                            <select className="form-control custom-select" id="series" name="series" value={this.state.series} onChange={this.handleChange}>
                                <option>未選択</option>
                                {this.state.seriesData.map((datas, idx) => (
                                    <option value={datas.id}>{datas.series_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group form-row mt-5">
                        <div className="col-12">
                            <p className="label">書籍種別<span className="required">※</span></p>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="bookType1" name="bookType" value="QR本・販売本" onChange={this.handleChange}
                                    className="custom-control-input" checked={this.state.bookType == "QR本・販売本" ? "Checked" : ""} />
                                <label className="custom-control-label" htmlFor="bookType1">QR本・販売本</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="bookType2" name="bookType" value="QR本" onChange={this.handleChange}
                                    className="custom-control-input" checked={this.state.bookType == "QR本" ? "Checked" : ""} />
                                <label className="custom-control-label" htmlFor="bookType2">QR本</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="bookType3" name="bookType" value="販売本" onChange={this.handleChange}
                                    className="custom-control-input" checked={this.state.bookType == "販売本" ? "Checked" : ""} />
                                <label className="custom-control-label" htmlFor="bookType3">販売本</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="bookType4" name="bookType" value="無料本" onChange={this.handleChange}
                                    className="custom-control-input" checked={this.state.bookType == "無料本" ? "Checked" : ""} />
                                <label className="custom-control-label" htmlFor="bookType4">無料本</label>
                            </div>
                        </div>
                    </div>

                    <div className="publishdata_version_data">
                        <table className="table fixed">
                            <thead className="thead-light">
                                <tr>
                                    <th></th>
                                    <th>現行</th>
                                    <th>更新</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>紙面バージョン<span className="required">※</span>
                                    </th>
                                    <td>N/A</td>
                                    <td>
                                        <input type="text" className="form-control" name="paperVersion" value={this.state.paperVersion} onChange={this.handleChange} placeholder="バージョン" />ver
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <hr className="mb-5" />

                    <div className="form-group form-row">
                        <div className="col-3">
                            <div className="form-row">
                                <div>
                                    <p className="label">アプリ内アイテムコード</p>
                                    <span className="form-text text-muted">（販売本）</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="row mb-3">
                                <div className="col-2">
                                    <p className="label text-right">iOS</p>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control" name="ios" value={this.state.ios} onChange={this.handleChange} placeholder="iOSアプリ内アイテムコード" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <p className="label text-right">Android</p>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control" name="android" value={this.state.android} onChange={this.handleChange} placeholder="Androidアプリ内アイテムコード" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-row">
                        <div className="col-3">
                            <div className="form-row">
                                <div>
                                    <p className="label">入手期限</p>
                                    <span className="form-text text-muted">（QR本・無料本）</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-3 pr-0">
                                    <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        selected={this.state.expStartDate ? new Date(this.state.expStartDate) : null}
                                        onChange={this.handleExpStartDate}
                                        onChangeRaw={this.handleChangeDateRaw}
                                        name="expStartDate"
                                        placeholder="開始日"
                                        locale='ja'
                                    />
                                </div>
                                <div className="col-1 text-center mt-1">
                                    〜
                                </div>
                                <div className="col-3 pl-0">
                                    <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        selected={this.state.expEndDate ? new Date(this.state.expEndDate) : null}
                                        onChange={this.handleExpEndDate}
                                        onChangeRaw={this.handleChangeDateRaw}
                                        name="expEndDate"
                                        placeholder="終了日"
                                        locale='ja'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-row">
                        <div className="col-3">
                            <div className="form-row">
                                <div>
                                    <p className="label">閲覧期間</p>
                                    <span className="form-text text-muted">（QR本・無料本）</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-3 pr-0">
                                    <input type="text" className="form-control" name="period" value={this.state.period} onChange={this.handleChange} placeholder="閲覧期間" />
                                </div>
                                <div className="col-1 text-center mt-1">
                                    日
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-row">
                        <div className="col-3">
                            <div className="form-row">
                                <div>
                                    <p className="label">割引率</p>
                                    <span className="form-text text-muted">（QR本・販売本 | 販売本）</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-3 pr-0">
                                    <input type="text" className="form-control" name="discountRate" value={this.state.discountRate} onChange={this.handleChange} placeholder="割引率" />
                                </div>
                                <div className="col-1 text-center mt-1">
                                    ％
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-row">
                        <div className="col-3">
                            <div className="form-row">
                                <div>
                                    <p className="label">読み放題設定<span className="required">※</span></p>
                                    <span className="form-text text-muted">（QR本・販売本 | 販売本）</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline3" name="subscription_plan" value="0" checked={this.state.subscription_plan == "0" ? "Checked" : ""} onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline3">対象外</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline4" name="subscription_plan" value="1" checked={this.state.subscription_plan == "1" ? "Checked" : ""} onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline4">ことりっぷpassport会員</label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group form-row">
                        <div className="col-3">
                            <div className="form-row">
                                <div>
                                    <p className="label">試し読み設定</p>
                                    <span className="form-text text-muted">（販売本）</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-2 col-sm-3">
                                    <button type="button" className="btn btn-primary" data-toggle="modal" disabled
                                        data-target="#browsModal">
                                        設定
                                    </button>
                                </div>
                                <div className="col-5 col-sm-6 mt-2 pl-0">
                                    試し読みページ数：未設定ページ
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-row">
                        <div className="col-3">
                            <p className="label">書籍説明文</p>
                        </div>
                        <div className="col-9">
                            <textarea className="form-control" rows="5" name="bookDesc" value={this.state.bookDesc} onChange={this.handleChange} ></textarea>
                        </div>
                    </div>
                    <div className="rgst_btn_wrap">
                        <button type="button" className="btn btn-light"
                            onClick={() => history.back()}>キャンセル
                        </button>
                        <button type="button" className="btn btn-danger" data-toggle="modal"
                            data-target="#convertModal">
                            保存
                        </button>
                    </div>
                </form>
            </main>
        );
    }
}
export default RegisterUpdatePublicationAdd;
