/* eslint-disable */
import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import moment from "moment";
import Loader from "react-loader-spinner";
import {
  reteriveRecommendedAPI,
  addRecommendedAPI,
  listPublicationDataAPI,
  reterivePublicationDataAPI
} from "../ApiIntegration";

registerLocale("ja", ja);

class RecommendedBooksEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      registerDate: "",
      lastModified: "",
      search: "",
      selectedBook: "",
      isbn: "",
      series: "",
      title: "",
      showType: false,
      data: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      bookLoader: false,
    };
  }
  searchBook = () => {
    this.listBookSearch(1, this.state.search);
  };

  editRecommendBookHandler = () => {
    const {
      isbn,
      startDate,
      endDate,
      showType,
      id,
    } = this.state;
    addRecommendedAPI(
      {
        id: id,
        isbn_edition: isbn,
        viewing_start: startDate,
        viewing_end: endDate,
        show_type: showType,
      },
      ({ response }) => {
        if (response.data.success == true) {
          NotificationManager.success(1, response.data.message, "Recommended Book Display Success");
          window.location.href = "/recommendations/";
        } else {
          const err = response.data.error;
          Object.keys(err).forEach((v) => {
            if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
          });
        }
      }
    );
  };

  listBookSearch = (page, search) => {
    this.setState({ bookLoader: true });
    listPublicationDataAPI({ search: search, page_no: page }, (apiResponse) => {
      if (apiResponse.response.data.status == true) {
        this.setState({
          data: apiResponse.response.data.data,
          bookLoader: false,
        });
      }
    });
  };
  booklist = () => {
    this.listBookSearch(1, this.state.search);
  };

  selectbook = (e) => {
    console.log(e.target.id);
    this.setState({ selectedBook: e.target.id });
    reterivePublicationDataAPI({ id: e.target.id }, (apiResponse) => {
      if (apiResponse.response.data.success == true) {
        this.setState({
          isbn: apiResponse.response.data.data[0].isbn_edition,
          series: apiResponse.response.data.data[0].series_name,
          title: apiResponse.response.data.data[0].title,
        });
      }
    });
  };

  handleStartdDate = (date) => {
    let _date = moment(date)
    this.setState({
      startDate: _date.isValid() ? _date.format("YYYY-MM-DD") : null
    });
  };
  handleEndDate = (date) => {
    let _date = moment(date)
    this.setState({
      endDate: _date.isValid() ? _date.format("YYYY-MM-DD") : null
    });
  };
  handleChangeDateRaw = e => {
    let value = e.target.value;
    if (value.length === 8) {
      let date = value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8)
      let _date = moment(date)
      this.setState({
        [e.target.name]: _date.isValid() ? _date.format("YYYY-MM-DD") : null
      })
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    document.title = "おすすめ本表示設定 > 新規登録";
    reteriveRecommendedAPI({ id: this.props.match.params.id }, (apiResponse) => {
      if (apiResponse.response.data.success == true) {
        this.setState({
          id: apiResponse.response.data.data.id,
          registerDate: apiResponse.response.data.data.created,
          lastModified: apiResponse.response.data.data.modified,
          isbn: apiResponse.response.data.data.isbn_edition,
          series: apiResponse.response.data.data.series_name,
          title: apiResponse.response.data.data.title,
          startDate: apiResponse.response.data.data.viewing_start,
          endDate: apiResponse.response.data.data.viewing_end,
          showType: apiResponse.response.data.data.show_type,
        });
      }
    });
  }

  render() {
    return (
      <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
        <nav aria-label="パンくずリスト">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item">おすすめ本表示設定</li>
            <li className="breadcrumb-item"> 編集</li>
          </ol>
        </nav>
        <div
          className="modal fade"
          id="exampleModalCenteredScrollable"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenteredScrollableTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal_large"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalCenteredScrollableTitle"
                >
                  書籍の検索
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="閉じる"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="list_search">
                  <div className="form-row justify-content-center">
                    <div className="form-group col-8">
                      <label>書籍名・ISBN版刷</label>
                      <input
                        type="text"
                        className="form-control mr-2"
                        placeholder="書籍名またはISBN版刷を入力"
                        id="search_name"
                        name="search"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-3 text-center pt-3">
                      <button
                        type="button"
                        className="btn btn-secondary book_search_btn"
                        onClick={this.searchBook}
                      >
                        検索
                      </button>
                    </div>
                  </div>
                </form>
                <div className="default_list_wrap modal_large_search ">
                  {this.state.bookLoader ? (
                    <Loader
                      type="ThreeDots"
                      color="#00BFFF"
                      style={{
                        margin: "0 auto",
                        display: "block",
                        textAlign: "center",
                      }}
                      height={100}
                      width={100}
                    />
                  ) : (
                      <table className="table default_list modal_table_list book_search_list">
                        <thead className="thead-light">
                          <tr>
                            <th className="w_12">ISBN版刷</th>
                            <th className="w_15">シリーズ名</th>
                            <th className="w_15">書籍名</th>
                            <th className="w_5"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.map((datas, idx) => (
                            <tr key={idx}>
                              <td>
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input  delete_check"
                                    checked={
                                      this.state.selectedBook == datas.id
                                        ? true
                                        : false
                                    }
                                    id={datas.id}
                                    onClick={this.selectbook}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={datas.id}
                                  >
                                    {" "}
                                    {datas.isbn_edition}
                                  </label>
                                </div>
                              </td>
                              <td>{datas.series_name}</td>
                              <td>{datas.title}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div className="modal fade" id="editmodeConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="閉じる">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>編集を破棄して一覧に戻りますか？</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal"
                  onClick={() => window.location.href = "/recommendations/"}>はい</button>
              </div>
            </div>
          </div>
        </div>
        
        <form className="recode_edit_form mb-6">
          <div className="form-group row border-bottom">
            <div className="col-3">
              <p className="label">ID</p>
              <p className="p_item">{this.state.id}</p>
            </div>
            <div className="col-3">
              <p className="label">登録日時</p>
              <p className="p_item">{this.state.registerDate}</p>
            </div>
            <div className="col-3">
              <p className="label">更新日時</p>
              <p className="p_item">
                {this.state.lastModified || ""}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-5">
              <div className="row">
                <div className="col-12  mb-2">
                  <h5>書籍情報</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModalCenteredScrollable"
                    onClick={this.booklist}
                  >
                    書籍の検索
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group col-7">
              <div className="row">
                <div className="col-12  mb-2">
                  <label>ISBN版刷</label>
                  <span className="required">※</span>
                  <input
                    type="text"
                    className="check_value readonly"
                    readonly
                    value={this.state.isbn || ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-2">
                  <label>シリーズ名</label>
                  <input
                    type="text"
                    className="check_value readonly"
                    readonly
                    value={this.state.series || ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-2">
                  <label>書籍名</label>
                  <input
                    type="text"
                    className="check_value readonly"
                    readonly
                    value={this.state.title || ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <p className="label">掲載期間</p>
              <div className="row">
                <div className="col-3 pr-0">
                  <DatePicker
                    className="form-control"
                    name="startDate"
                    selected={this.state.startDate ? new Date(this.state.startDate) : null}
                    onChange={this.handleStartdDate}
                    onChangeRaw={this.handleChangeDateRaw}
                    dateFormat="yyyy-MM-dd"
                    placeholder="開始日"
                    locale="ja"
                  />
                </div>
                <div className="col-1 date_while">〜</div>
                <div className="col-3 pl-0">
                  <DatePicker
                    className="form-control"
                    name="endDate"
                    selected={this.state.endDate ? new Date(this.state.endDate) : null}
                    onChange={this.handleEndDate}
                    onChangeRaw={this.handleChangeDateRaw}
                    dateFormat="yyyy-MM-dd"
                    placeholder="終了日"
                    locale="ja"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <p className="label">
                非表示／表示<span className="required">※</span>
              </p>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                  checked={this.state.showType}
                  onChange={() => this.setState({ showType: !this.state.showType })}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitch1"
                ></label>
              </div>
            </div>
          </div>
          <div className="rgst_btn_wrap">
            <button type="button" className="btn btn-light" data-toggle="modal" 
              data-target="#editmodeConfirm">キャンセル
            </button>
            <button
              type="button"
              className="btn btn-danger update"
              onClick={this.editRecommendBookHandler}
            >
              保存
            </button>
          </div>
        </form>
      </main>
    );
  }
}
export default RecommendedBooksEdit;
