/* eslint-disable */
import axios from "axios";

// export const API_BASE_URL = "http://172.105.41.233:1234/api";
import { API_BASE_URL } from "./Constant";

// export const API_BASE_URL = "http://192.168.0.17:1234/api";
// export const API_BASEURL_IMG="http://192.168.0.105:8080/media/import/customer_register.xls"

export let companyId1 = localStorage.getItem("company");
let companyId = localStorage.getItem("company");
export const isLoggedIn = () => localStorage.getItem("token");
export const userType = () => localStorage.getItem("usertype");

function handlerError(error, callback) {
  if (error.message == "Network Error") {
    // location.href = "/server";
    alert(
      "There is a problem while connecting to server",
      "Network Error"
    );
  }
  if (error.response == undefined) {
    console.log();
  } else if (error.response.status == 500) {
    location.href = "/server";
  } else if (error.response.status == 401) {
    localStorage.removeItem("token");
    localStorage.removeItem("company");
    localStorage.removeItem("usertype");
    localStorage.removeItem("username");
    localStorage.removeItem("logo");
    location.href = "/";
  } else {
    callback &&
      callback({
        status: "error",
        response: error
      });
  }
}
// For Authorization

export const loginAPI = (username, password, callback) => {
  let url = `${API_BASE_URL}/brand/signin/`;
  axios
    .post(url, {
      username: username,
      password: password
    })
    .then(response => {
      console.log("tttttttttttt", response)
      if (response.data["success"] == true) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("company", response.data.user_id);
        localStorage.setItem("usertype", response.data.user_type);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("nickname", response.data.nickname);
        localStorage.setItem("logo", response.data.logo);
      }
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};
export const logoutAPI = callback => {
  let url = `${API_BASE_URL}/brand/logout/`;
  axios
    .post(
      url,
      {},
      {
        headers: { Authorization: "Token " + localStorage.getItem("token") }
      }
    )
    .then(response => {
      localStorage.removeItem("token");
      localStorage.removeItem("company");
      localStorage.removeItem("username");
      localStorage.removeItem("usertype");
      localStorage.removeItem("logo");

      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const addPublicationAreaAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/area/create_update/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const listPublicationAreaAPI = callback => {
  let url = `${API_BASE_URL}/brand/area/list/`;
  axios
    .post(url, {}, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const reterivePublicationAreaAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/area/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const deleteAreaAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/area/delete/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};


export const addSeriesAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/series/create_update/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const listSeriesAPI = callback => {
  let url = `${API_BASE_URL}/brand/series/list/`;
  axios
    .post(url, {}, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const reteriveSeriesAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/series/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const deleteSeriesAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/series/delete/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};


export const listPublicationDataAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/book/list/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const addPublicationDataAPI = (payload, callback) => {
  const formData = new FormData();
  for (var k in payload){
    formData.append(k, payload[k]);
  }
  let url = `${API_BASE_URL}/brand/book/create_update/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const uploadTsvBookAPI = (file, epub, callback) => {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("convert_epub", epub);
  let url = `${API_BASE_URL}/brand/book/import/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const reterivePublicationDataAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/book/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const uploadingBookAPI = (file, callback) => {

  const formData = new FormData();
  console.log(file.length)
  for (var i = 0; i < file.length; i++) {
    let files = file[i];
    console.log('image[' + i + ']', files)
    formData.append('image' + i, files);
  }
  // formData.append("image", file);
  let url = `${API_BASE_URL}/brand/book/bulkupload/`;
  axios
    .post(url, formData, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};


export const listOneTimeUrlAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/one_time_url/book/list/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};


export const reteriveOneTimeUrlAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/one_time_url/book/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};
export const addOneTimeUrlAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/one_time_url/book/create/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const bookEncryptionAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/book/encrypt/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") },
    })
    .then((response) => {
      callback &&
        callback({
          status: "success",
          response: response,
        });
    })
    .catch((error) => {
      handlerError(error, callback);
    });
};

export const browsePublicationDataAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/book/browse/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const API_IMAGE_URL = `${API_BASE_URL}/brand/book/thumbnail/`

export const updateBrowsePublicationAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/book/browse/update/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const addRecommendedAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/recommended_books/create_update/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const listRecommendedAPI = callback => {
  let url = `${API_BASE_URL}/brand/recommended_books/list/`;
  axios
    .post(url, {}, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const reteriveRecommendedAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/recommended_books/retrieve/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const deleteRecommendedAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/recommended_books/delete/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const sortRecommendedAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/recommended_books/sort/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};

export const sortAreasAPI = (payload, callback) => {
  let url = `${API_BASE_URL}/brand/area/sort/`;
  axios
    .post(url, payload, {
      headers: { Authorization: "Token " + localStorage.getItem("token") }
    })
    .then(response => {
      callback &&
        callback({
          status: "success",
          response: response
        });
    })
    .catch(error => {
      handlerError(error, callback);
    });
};
