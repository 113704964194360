/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Link } from "react-router-dom";
import { listOneTimeUrlAPI } from "../ApiIntegration";
import Pagination from "../../Utils/DatatablePagination";
import ReactTable from "react-table";
import "react-table/react-table.css";

class OneTimeUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      data: [],
      dataLength: "",
      page: 1,
      pages: 2,
      loading: false,
    };
  }

  onPageChange = (e) => {
    this.setState({ page: e + 1 });
    this.listOneTimeURL(e + 1, this.state.search);
  };

  handlleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  componentDidMount() {
    document.title = "ワンタイムURLスキーム発行";
    this.listOneTimeURL(this.state.page, this.state.search);
    localStorage.removeItem("url")
    localStorage.removeItem("endDate")
    localStorage.removeItem("limitedCount")
  }
  listOneTimeURL = (page, search) => {
    this.setState({ loading: true });
    listOneTimeUrlAPI({ search: search, page_no: page}, (apiResponse) => {
      if (apiResponse.response.data.status == true) {
        this.setState(
          {
            data: apiResponse.response.data.data,
            pages: apiResponse.response.data.page.total_pages,
            page: this.state.page,
          },
          () => this.setState({ loading: false })
        );
      }
    });
  };
  searching = () => {
    this.listOneTimeURL(this.state.page, this.state.search.trim());
  };
  reset = () => {
    this.setState({ search: ""});
    this.listOneTimeURL(this.state.page, "");
  };
  onSubmit = e => {
    e.preventDefault();
    this.searching();
  }

  render() {
    return (
      <main role="main" class="col-md-10 ml-sm-auto col-lg-10 px-4">
        <nav aria-label="パンくずリスト">
          <ol class="breadcrumb bg-white">
            <li class="breadcrumb-item">ワンタイムURLスキーム発行</li>
          </ol>
        </nav>
        <div
          class="alert alert-danger alert-dismissible fade show none"
          role="alert"
        >
          保存に失敗しました。
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="閉じる"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="alert alert-success alert-dismissible fade show none"
          role="alert"
        >
          保存に成功しました。
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="閉じる"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="row">
          <div class="col-3">
            <p>
              <button
                type="button"
                class="btn btn-outline-primary list_search_btn"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                検索エリア非表示
              </button>
            </p>
          </div>
        </div>
        <br />
        <div class="collapse show list_search_box" id="collapseExample">
          <div class="card card-body p-3">
            <form class="list_search" onSubmit={this.onSubmit}>
              <div class="form-row flex">
                <div class="form-group col-4">
                  <label>書籍名・ISBN</label>
                  <input
                    type="text"
                    class="form-control mb-2 mr-2"
                    placeholder="書籍名またはISBNを入力"
                    id="search_name"
                    name="search"
                    value={this.state.search}
                    onChange={this.handlleChange}
                  />
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class="col-12 text-center">
                  <button
                    type="button"
                    class="btn btn-light mr-1"
                    onClick={this.reset}
                  >クリア</button>
                  <button type="submit" class="btn btn-secondary ml-1">
                    検索
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="default_list_wrap mb-5 mt-4">
          <ReactTable
            data={this.state.data}
            pages={this.state.pages}
            loading={this.state.loading}
            manual
            page={this.state.page - 1}
            showPageJump={false}
            showPageSizeOptions={false}
            PaginationComponent={Pagination}
            onPageChange={this.onPageChange}
            minRows={2}
            className="table thead th"
            sortable={false}
            columns={[
              {
                Header: () => <div className="text-center">シリーズ名</div>,
                headerStyle: {fontWeight: "bold"},
                accessor: "series_name",
                width: 200,
                Cell: (props) => (
                  <p className="table.default_list td">
                    {props.value ? props.value : ""}{" "}
                  </p>
                ),
              },
              {
                Header: () => <div className="text-center">ISBN版刷</div>,
                headerStyle: {fontWeight: "bold"},
                accessor: "isbn_edition",
                width: 200,
                Cell: (props) => (
                  <p className="table.default_list td">
                    {props.value ? props.value : "N/A"}
                  </p>
                ),
              },
              {
                Header: () => <div className="text-center">書籍名</div>,
                headerStyle: {fontWeight: "bold"},
                accessor: "title",
                Cell: (props) => (
                  <Link to={"/onetimeurl_edit/" + props.original.id}> <p className="table.default_list td">
                    {props.value ? props.value : ""}
                  </p></Link>
                ),
              },
              {
                Header: () => <div className="text-center">書籍種別</div>,
                headerStyle: {fontWeight: "bold"},
                accessor: "book_type",
                width: 180,
                Cell: (props) => (
                  <p className="table.default_list td text-center">
                    {props.value ? props.value : ""}
                  </p>
                ),
              },
              {
                Header: () => <div className="text-center">発売日</div>,
                headerStyle: {fontWeight: "bold"},
                accessor: "publication_date",
                width: 180,
                Cell: (props) => (
                    <p className="table.default_list td text-center">
                      {props.value ? props.value : ""}
                    </p>
                ),
              },
              {
                Header: () => <div className="text-center">ダウンロード期限</div>,
                headerStyle: {fontWeight: "bold"},
                accessor: "download_deadline",
                width: 180,
                Cell: (props) => (
                  <p className="table.default_list td text-center">
                    {props.value ? props.value : ""}
                  </p>
                ),
              },
            ]}
          />
        </div>
      </main>
    );
  }
}
export default OneTimeUrl;
