/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { NotificationManager } from "react-notifications";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import { listRecommendedAPI, sortRecommendedAPI, deleteRecommendedAPI } from "../ApiIntegration";
import {
  TrComponent,
  TrComponentDnD,
} from "../../Utils/DragAndDrop";

class RecommendedBooksCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      editMode: false,
      selectedArea: [],
    };
  }

  selectRecommended = (e) => {
    let itemArray = [];
    if (this.state.selectedArea != "") {
      itemArray = [...this.state.selectedArea]; // make a separate copy of the array
    } else {
      itemArray = [];
    }
    if (e.target.checked == true) {
      itemArray.push(e.target.name);
    } else {
      var index = itemArray.indexOf(e.target.name);
      itemArray.splice(index, 1);
    }
    this.setState({ selectedArea: itemArray });
  };

  componentDidMount() {
    document.title = "おすすめ本表示設定";
    this.listRecommendBookDisplay();
  }

  listRecommendBookDisplay = () => {
    this.setState({ loading: true });
    listRecommendedAPI(apiResponse => {
      if (apiResponse.response.data.success == true) {
        this.setState({
          data: apiResponse.response.data.data,
          loading: false,
        });
      }
    });
  };

  delete = () => {
    deleteRecommendedAPI({ id: this.state.selectedArea }, (response) => {
      if (response.response.data.success == true) {
        NotificationManager.success(response.response.data.message, "Delete Success", 3000);
        this.setState({ editMode: false });
        this.listRecommendBookDisplay();
      } else {
        const err = response.data.error;
        Object.keys(err).forEach((v) => {
          if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
        });
      }
    });
  };

  onDrop = (item, newId) => {
    const id = this.state.data[item.id].id;
    const pos = newId;
    this.sortDnD(id, pos);
  };
  sortDnD = (id, pos) => {
    sortRecommendedAPI({ id: id, pos: pos }, (response) => {
      if (response.response.data.success == true) {
        this.listRecommendBookDisplay();
      } else {
        const err = response.data.error;
        Object.keys(err).forEach((v) => {
          if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
        });
      }
    });
  };

  render() {
    const { editMode, data } = this.state;
    return (
      <main
        role="main"
        className="tp_recommend col-md-10 ml-sm-auto col-lg-10 px-4"
      >
        <nav aria-label="パンくずリスト">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item"> おすすめ本表示設定</li>
          </ol>
        </nav>

        <div className="row">
          <div className="col list_btn_wrap">
            <button
              type="button"
              className="btn btn-primary add"
              onClick={() => window.location.href = "/recommendations_add"}
            >
              新規登録
            </button>
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => this.setState({ editMode: true })}
            > 編集
            </button>
            {editMode && (
              <>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => this.setState({ editMode: false })}
                >
                  キャンセル
                </button>
                {this.state.selectedArea.length > 0 && <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.delete}
                >
                  削除
                </button>}
              </>
            )}
          </div>
        </div>
        <div className="default_list_wrap mb-5 ">
          <DndProvider backend={HTML5Backend}>
            <ReactTable
              TrComponent={({ children, className, ...rest }) => {
                if (editMode)
                  return (
                    <TrComponentDnD
                      children={children}
                      className={className}
                      onDrop={this.onDrop}
                      {...rest}
                    />
                  );
                else
                  return (
                    <TrComponent
                      children={children}
                      className={className}
                      {...rest}
                    />
                  );
              }}
              data={this.state.data}
              showPagination={false}
              defaultPageSize={200}
              sortable={false}
              minRows={2}
              className="table thead th"
              columns={[
                {
                  Header: "削除",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "id",
                  width: 50,
                  show: this.state.editMode ? true : false,
                  Cell: (props) => (
                    <p className="list_img table.default_list td">
                      <input
                        type="checkbox"
                        onChange={this.selectRecommended}
                        name={props.value}
                        id={props.value}
                        checked={
                          this.state.selectedArea.indexOf(
                            props.value.toString()
                          ) > -1
                        }
                      />
                    </p>
                  ),
                },
                {
                  Header: "ISBN版刷",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "isbn_edition",
                  Cell: (props) => (
                    <p className="list_img table.default_list td">
                      {props.value ? props.value : ""}
                    </p>
                  ),
                },
                {
                  Header: "シリーズ名",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "series_name",
                  Cell: (props) => (
                    <p className="table.default_list td">
                      {props.value ? props.value : ""}
                    </p>
                  ),
                },
                {
                  Header: "書籍名",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "title",
                  Cell: (props) => (
                    <Link to={`/recommendations_edit/${props.original.id}/`} >
                      <p className="table.default_list td text-wrap">
                        {props.value ? props.value : ""}
                      </p>
                    </Link>
                  ),
                },
                {
                  Header: "掲載期間",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "viewing_start",
                  Cell: (props) => (
                    <p className="table.default_list td">
                      {props.value + " - " + props.original.viewing_end}
                    </p>
                  ),
                },
                {
                  Header: "表示/非表示",
                  headerStyle: {fontWeight: "bold"},
                  accessor: "show_type",
                  width:100,
                  Cell: (props) => (
                    <p className="table.default_list td">
                      {props.value ? "表示" : "非表示"}
                    </p>
                  ),
                },
                {
                  accessor: "created",
                  Header: () => <div className="text-center">登録日時</div>,
                  headerStyle: {fontWeight: "bold"},
                  Cell: (props) => (
                    <p className="table.default_list td" style={{ textAlign: "center" }}>
                      {props.value ? props.value.slice(0, 10) : ""} {<br />}
                      {props.value ? props.value.slice(11, 16) : ""}
                    </p>
                  ),
                },
                {
                  accessor: "modified",
                  Header: () => <div className="text-center">更新日時</div>,
                  headerStyle: {fontWeight: "bold"},
                  Cell: (props) => (
                    <p className="table.default_list td" style={{ textAlign: "center" }}>
                      {props.value ? props.value.slice(0, 10) : ""} {<br />}
                      {props.value ? props.value.slice(11, 16) : ""}
                    </p>
                  ),
                },
              ]}
            />
          </DndProvider>
        </div>
      </main>
    );
  }
}
export default RecommendedBooksCard;
