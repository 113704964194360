/* eslint-disable */
import React from "react";
import { Route, Switch } from "react-router-dom";
// import { isLoggedIn, userType } from "./ApiIntegration";
// Brand Routings
import 'react-notifications/lib/notifications.css';

import { NotificationContainer } from 'react-notifications';

import IndexCard from "./index/IndexCard";
import UserLayout from "../layout/UserLayout";
import AppLayout from "../layout/AppLayout";
import RegisterUpdatePublicationCard from "./registerUpdatePublication/RegisterUpdatePublicationCard"
import RegisterUpdatePublicationAdd from "./registerUpdatePublication/RegisterUpdatePublicationAdd"
import RegisterUpdatePublicationEdit from "./registerUpdatePublication/RegisterUpdatePublicationEdit"

import RegisterUpdatePublicationBulk from "./registerUpdatePublication/RegisterUpdatePublicationBulk"
import RegisterUpdatePublicationBrows from "./registerUpdatePublication/RegisterUpdatePublicationBrows"
import PublishSeries from "./publishSeries/PublishSeriesCard";
import PublishSeriesAdd from "./publishSeries/PublishSeriesAdd"
import PublishSeriesEdit from "./publishSeries/PublishSeriesEdit"
import PublishAreas from "./publishAreas/PublishAreasCard"
import PublishAreasAdd from "./publishAreas/PublishAreasAdd"
import PublishAreasEdit from "./publishAreas/PublishAreasEdit"
import OneTimeUrl from "./oneTimeUrl/OneTimeUrlCard";
import RegisterUpdateBulkUploading from "./registerUpdatePublication/RegisterUpdateBulkUploading";
import OneTimeUrlEdit from "./oneTimeUrl/OneTimeUrlEdit";
import OneTimeUrlConfirm from "./oneTimeUrl/OneTimeUrlConfirm";

import RecommendedBooksAdd from "./recommendedBooks/RecommendedBooksAdd";
import RecommendedBooksCard from "./recommendedBooks/RecommendedBooksCard";
import RecommendedBooksEdit from "./recommendedBooks/RecommendedBooksEdit";

const WrappedWithoutLoginRoutes = () => (

  <UserLayout>
    <Route path="/" component={IndexCard} />
    <NotificationContainer/>

    </UserLayout>

);

const Router = () => (
  <Switch>
    <Route
       exact path="/"
      render={() => {
        const loggedIn = false
        // const loggedIn = isLoggedIn();
        // const usertype = userType();
        if (!loggedIn) {
          return <WrappedWithoutLoginRoutes />;
        } 
      }}
    /> 
     <AppLayout>
      <Route path="/publishdatas/" component={RegisterUpdatePublicationCard} />
      <Route path="/publishdatas_add/" component={RegisterUpdatePublicationAdd} />
      <Route path="/publishdatas_edit/:id/" component={RegisterUpdatePublicationEdit} />
      <Route path="/publishdatas_regist/" component={RegisterUpdatePublicationBulk} />
      <Route path="/publishdatas_brows/" component={RegisterUpdatePublicationBrows} />
      <Route path="/publishdatas_bulk_upload/" component={RegisterUpdateBulkUploading} />
      <Route path="/publishseries/" component={PublishSeries} />
      <Route path="/publishseries_add/" component={PublishSeriesAdd} />
      <Route path="/publishseries_edit/:id/" component={PublishSeriesEdit} />
      <Route path="/publishareas/" component={PublishAreas} />
      <Route path="/publishareas_add/" component={PublishAreasAdd} />
      <Route path="/publishareas_edit/:id/" component={PublishAreasEdit} />
      <Route path="/onetimeurl/" component={OneTimeUrl} />
      <Route path="/onetimeurl_edit/:id/" component={OneTimeUrlEdit} />
      <Route path="/onetimeurl_confirm/:id/" component={OneTimeUrlConfirm} />
      <Route path="/recommendations/" component={RecommendedBooksCard} />
      <Route path="/recommendations_add/" component={RecommendedBooksAdd} />
      <Route path="/recommendations_edit/:id/" component={RecommendedBooksEdit} />

    </AppLayout>
    <NotificationContainer/>

  </Switch>
)

export default Router;
