import React, { Component } from "react";
import "../assets/css/style.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/spectrum.css"
import "../assets/css/quill.snow.css"
import "../assets/css/reset.css"
import "../assets/css/jquery-ui.css"
import "../assets/css/image-picker.css"
import '../assets/css/notifications.css';

import {NotificationContainer} from 'react-notifications';
import Sidebar from "../Containers/Sidebar";
import TopNav from "../Containers/TopNav"

class AppLayout extends Component {
    render() {
        return (
            <>
                    <NotificationContainer />

                <TopNav {...this.props} />
                <div className="container-fluid">
                    <div className="row">
                    <Sidebar {...this.props}/>
                        {this.props.children}
                    </div>
                </div>
            </>
        );
    }
}

export default AppLayout;