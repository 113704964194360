import React, { Component, Fragment } from "react";
import "../assets/css/style.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/spectrum.css"
import "../assets/css/quill.snow.css"
import "../assets/css/reset.css"
import "../assets/css/jquery-ui.css"
import '../assets/css/notifications.css';

import {NotificationContainer} from 'react-notifications';

class UserLayout extends Component {
  render() {
    return (
      <Fragment>
        <main>
        <NotificationContainer variant="outlined" />

          <div className="container">{this.props.children}</div>
        </main>
      </Fragment>
    );
  }
}

export default UserLayout;