/* eslint-disable */
import React, { Component, Fragment } from "react";
import {
    Link
} from 'react-router-dom';
import { addPublicationAreaAPI } from "../ApiIntegration";
import { NotificationManager } from 'react-notifications';

class PublishAreasAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areaCode: "",
            areaName: "",
            countryType: "",
            status: false,
        };
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleStatus = (e) => {
        this.setState({ status: !this.state.status })
    }

    addAreaHandler = () => {
        const { areaCode, areaName, countryType, status } = this.state;
        addPublicationAreaAPI({
            area_name: areaName, area_code: areaCode,
            oversea: countryType, status: status
        }, ({ response }) => {
            if (response.data.success == true) {
                NotificationManager.success(1, response.data.message, "Pulication Area Success")
                window.location.href = "/publishareas/";
            }
            else {
                const err = response.data.error;
                Object.keys(err).forEach(v => {
                    if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
                });
            }
        });
    };


    componentDidMount() {
        document.title = "出版物エリアマスタ"
    }

    render() {
        return (
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
                <nav aria-label="パンくずリスト">
                    <ol className="breadcrumb bg-white">
                        <li className="breadcrumb-item">出版物エリアマスタ</li>
                        <li className="breadcrumb-item">新規作成</li>
                    </ol>
                </nav>
                <form className="recode_edit_form">
                    <div className="form-group row">
                        <div className="col-3">
                            <label>エリアコード</label><span className="required">※</span>
                            <input type="text" name="areaCode" value={this.state.areaCode} className="form-control" placeholder="エリアコード" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-6">
                            <label>エリア名</label><span className="required">※</span>
                            <input type="text" name="areaName" value={this.state.areaName} className="form-control" placeholder="エリア名" onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <p className="label">国種<span className="required">※</span></p>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="countryType" value="0" onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline1">国内</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="countryType" value="1" onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline2">海外</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12">
                            <p className="label">無効／有効<span className="required">※</span></p>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.status} onChange={this.handleStatus} />
                                <label className="custom-control-label" htmlFor="customSwitch1"></label>
                            </div>
                        </div>
                    </div>
                    <div className="rgst_btn_wrap">
                        <Link to="/publishareas/">
                            <button type="button" className="btn btn-light"
                            >キャンセル
                    </button>
                        </Link>

                        <button type="button" className="btn btn-danger" onClick={this.addAreaHandler}
                        >保存
                        </button>

                    </div>
                </form>
            </main>
        );
    }
}
export default PublishAreasAdd;
