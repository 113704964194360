/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";

import { Link } from "react-router-dom";
import {
  reteriveOneTimeUrlAPI,
  addOneTimeUrlAPI,
} from "../ApiIntegration";
import { NotificationManager } from "react-notifications";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import moment from "moment";

registerLocale("ja", ja);

class OneTimeUrlEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      downloadDeadline: "",
      epubCover: "",
      isbnEdition: "",
      publicationDate: "",
      endDate: moment().format("YYYY-MM-DD"),
      endTime: "",
      seriesName: "",
      title: "",
      limitedCount: false,
      setting: "Period",
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSetting = (e) => [
    this.setState({ setting: e.target.value })
  ]
  handleStatus = (e) => {
    this.setState({ limitedCount: !this.state.status });
  };
  componentDidMount() {
    document.title = "ワンタイムURLスキーム発行";
    reteriveOneTimeUrlAPI(
      { id: this.props.match.params.id },
      (apiResponse) => {
        if (apiResponse.response.data.success == true) {
          this.setState({
            id: apiResponse.response.data.data[0].id,
            downloadDeadline: apiResponse.response.data.data[0].download_deadline,
            epubCover: apiResponse.response.data.data[0].epub_cover,
            isbnEdition: apiResponse.response.data.data[0].isbn_edition,
            publicationDate: apiResponse.response.data.data[0].publication_date,
            seriesName: apiResponse.response.data.data[0].series_name,
            title: apiResponse.response.data.data[0].title,

          });
        }
      }
    );
  }

  handleEndDate = (date) => {
    this.setState({
      endDate: moment(date).format("YYYY-MM-DD"),
    });
  };
  handleEndTime = (e) => {
    this.setState({ endTime: e.target.value })
  }
  handleStatus = (e) => {
    this.setState({ limitedCount: !this.state.limitedCount });
  };
  handleperiodTime = (e) => {

    var currentDate = moment();
    var futureMonth = moment(currentDate).add(e.target.value, 'M');
    this.setState({
      endDate: moment(futureMonth).format("YYYY-MM-DD"),
    });

  }

  saveOneTimeURL = () => {
    const { endDate, limitedCount, id } = this.state;
    addOneTimeUrlAPI(
      {
        id: id,
        expire_date: endDate,
        limited_count: limitedCount ? 0 : 1,
      },
      ({ response }) => {
        console.log("vvvvvvvvvvvvvvvv", response)
        if (response.data.success == true) {
          localStorage.setItem("url", response.data.sharable_url)
          localStorage.setItem("endDate", this.state.endDate)
          localStorage.setItem("limitedCount", this.state.limitedCount)

          NotificationManager.success(
            response.data.message,
            "One Time URL Success",
            3000
          );
          window.location.href = "/onetimeurl_confirm/" + this.state.id + "/";
        } else {
          const err = response.data.error;
          Object.keys(err).forEach((v) => {
            if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
          });
        }
      }
    );
  };

  render() {
    return (
      <main role="main" class="col-md-10 ml-sm-auto col-lg-10 px-4">
        <nav aria-label="パンくずリスト">
          <ol class="breadcrumb bg-white">
            <li class="breadcrumb-item">ワンタイムURLスキーム発行</li>
            <li class="breadcrumb-item">一時コード発行</li>
          </ol>
        </nav>

        <div className="modal fade" id="editmodeConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="閉じる">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>編集を破棄して一覧に戻りますか？</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal"
                  onClick={() => window.location.href = "/onetimeurl/"}>はい</button>
              </div>
            </div>
          </div>
        </div>

        <form class="recode_edit_form">
          <div class="form-group row">
            <div class="col-5">
              <div class="form-group">
                <p class="label">選択シリーズ名・書籍名</p>
                <p class="p_item">{this.state.seriesName}/ {this.state.title}</p>
              </div>
              <div class="form-group">
                <p class="label">出版日</p>
                <p class="p_item">{this.state.publicationDate ? this.state.publicationDate : ""}</p>
              </div>
              <div class="form-group">
                <p class="label">ダウンロード期限</p>
                <p class="p_item">{this.state.downloadDeadline ? this.state.downloadDeadline : ""}</p>
              </div>
            </div>
            <div class="col-7">
              <div class="onetime_img">
                {this.state.epubCover ?
                  <img src={this.state.epubCover} />
                  :
                  <img src={require("../../assets/img/sample3.png")} />
                }
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-3">
              <p class="label">有効期限</p>
            </div>
          </div>
          <div class="form-group row pl-4">
            <div class="col-2">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input onetime_limite_while"
                  type="radio"
                  name="onetime_limit"
                  id="inlineRadio1"
                  value="Period"
                  data-org="期間設定"
                  checked={this.state.setting == "Period" ? true : false}
                  onChange={this.handleSetting}
                />
                <label class="form-check-label" htmlFor="inlineRadio1">
                  期間設定
                                </label>
              </div>
            </div>
            {this.state.setting == "Period" &&
              <div class="col-6">
                <label htmlFor="exampleFormControlSelect1" class="sr-only">
                  有効期限選択
                                </label>
                <select
                  class="form-control onetime_select_while"
                  id="exampleFormControlSelect1"
                  onChange={this.handleperiodTime}
                >
                  <option>期間を選択してください</option>
                  <option value="1">1ヵ月</option>
                  <option value="3">3ヵ月</option>
                  <option value="6">6ヵ月</option>
                  <option value="12">12ヵ月</option>
                </select>
              </div>
            }
          </div>

          <div class="form-group row pl-4">
            <div class="col-2">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input onetime_limite_date"
                  type="radio"
                  name="onetime_limit"
                  id="inlineRadio2"
                  value="date"
                  data-org="日付設定"
                  checked={this.state.setting == "date" ? true : false}
                  onChange={this.handleSetting}

                />
                <label class="form-check-label" htmlFor="inlineRadio2">
                  日付設定
                    </label>
              </div>
            </div>
            {this.state.setting == "date" &&
              <>
                <div class="col-3">
                  <label class="sr-only">日付設定（日付）</label>
                  <DatePicker
                    className="form-control"
                    name="startDate"
                    value={this.state.endDate}
                    onChange={this.handleEndDate}
                    placeholder="開始日"
                    locale="ja"
                  />
                </div>
                <div class="col-3">
                  <label class="sr-only">日付設定（時間）</label>
                  <input
                    type="time"
                    className="form-control"
                    name="endTime"
                    value={this.state.endTime}
                    onChange={this.handleEndTime}
                    placeholder="終了日"
                    locale="ja"
                  />
                </div>
              </>
            }
          </div>

          <div class="form-group row">
            <div class="col-12">
              <p class="label">回数制限</p>
              <div class="form-check ml-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="defaultCheck1"
                  onChange={this.handleStatus}
                />
                <label class="form-check-label" htmlFor="defaultCheck1">
                  無制限
                    </label>
                <span class="form-text text-muted">
                  1回のみの制限を外す場合は、チェックを入れてください。
                    </span>
              </div>
            </div>
          </div>
          <div class="rgst_btn_wrap">
            <button type="button" className="btn btn-light" 
              data-toggle="modal" data-target="#editmodeConfirm">キャンセル
            </button>
            <button
              type="button"
              class="btn btn-danger update"
              onClick={this.saveOneTimeURL}
            >
              次へ
                </button>
          </div>
        </form>
      </main>
    );
  }
}
export default OneTimeUrlEdit;
