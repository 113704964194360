/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Row, } from "reactstrap";

import {
    Link
} from 'react-router-dom';
import { addSeriesAPI } from "../ApiIntegration"
import { NotificationManager } from 'react-notifications';

class PublishSeriesAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesCode: "",
            seriesName: "",
            countryType: "",
            status: false,
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleStatus = (e) => {

        this.setState({ status: !this.state.status })
    }

    componentDidMount() {
        document.title = "出版物シリーズマスタ"

    }
    addSeriesHandler = () => {
        const { seriesCode, seriesName, countryType, status } = this.state;
        addSeriesAPI({
            series_name: seriesName, series_code: seriesCode,
            oversea: countryType, status: status
        }, ({ response }) => {
            if (response.data.success == true) {
                NotificationManager.success(response.data.message, "Pulication Series Success", 3000)
                window.location.href = "/publishseries/";
            }
            else {
                const err = response.data.error;
                Object.keys(err).forEach(v => {
                    if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
                });
            }
        });
    }

    render() {

        return (
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
                <nav aria-label="パンくずリスト">
                    <ol className="breadcrumb bg-white">
                        <li className="breadcrumb-item">出版物シリーズマスタ</li>
                        <li className="breadcrumb-item">新規作成</li>
                    </ol>
                </nav>
                <form className="recode_edit_form">
                    <div className="form-group row">
                        <div className="col-3">
                            <label>シリーズコード</label><span className="required">※</span>
                            <input type="text" className="form-control" name="seriesCode" value={this.state.seriesCode} onChange={this.handleChange}
                                placeholder="シリーズコード" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-6">
                            <label>シリーズ名</label><span className="required">※</span>
                            <input type="text" className="form-control" placeholder="シリーズ名" name="seriesName" value={this.state.seriesName} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12">
                            <p className="label">国種<span className="required">※</span></p>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline1" name="countryType" value="0" onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline1">国内</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="customRadioInline2" name="countryType" value="1" onChange={this.handleChange}
                                    className="custom-control-input" />
                                <label className="custom-control-label" htmlFor="customRadioInline2">海外</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-12">
                            <p className="label">無効／有効<span className="required">※</span></p>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.status} onChange={this.handleStatus} />
                                <label className="custom-control-label" htmlFor="customSwitch1"></label>
                            </div>
                        </div>
                    </div>

                    <div className="rgst_btn_wrap">
                        <Link to="/publishseries/">
                            <button type="button" className="btn btn-light"
                            >キャンセル
                    </button>
                        </Link>
                        <button type="button" className="btn btn-danger" onClick={this.addSeriesHandler}
                        >保存
                    </button>
                    </div>
                </form>
            </main>
        );
    }
}
export default PublishSeriesAdd;
