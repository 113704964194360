/* eslint-disable */
import React, { Component, } from "react";
import { uploadTsvBookAPI } from "../ApiIntegration"
import { NotificationManager } from 'react-notifications';

class RegisterUpdatePublicationBulk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: "",
            label: "ファイル選択（tsvファイル）",
            convertEpub: false,
        };
    }
    componentDidMount() {
        document.title = "出版物データ登録・更新"
    }
    handleChangeImage = e => {
        let file = e.target.files[0];
        this.setState({ file, label: file ? file.name : "ファイル選択（tsvファイル）" })

    };
    cancelFile = e => {
        this.setState({ file: null, label: "ファイル選択（tsvファイル）"})
    }
    checkEpub = (e) => {
        this.setState({ convertEpub: !this.state.convertEpub })
    }
    bulkRegistration = () => {
        console.log(this.state.file);
        uploadTsvBookAPI(this.state.file, this.state.convertEpub, response => {
            if (response.response.data.success == true) {
                NotificationManager.success(response.response.data.message, "Success", 4000)
                this.props.history.goBack();
            } else {
                const err = response.response.data.error;
                Object.keys(err).forEach(v => {
                    if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
                });
            }
        });
    }
    render() {
        return (
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
                <nav aria-label="パンくずリスト">
                    <ol className="breadcrumb bg-white">
                        <li className="breadcrumb-item">出版物データ登録・更新</li>
                        <li className="breadcrumb-item">一括登録</li>
                    </ol>
                </nav>
                <div className="container rgst">
                    <div className="row justify-content-center">
                        <div className="col-8 mt-5">
                            <h5>出版物を一括更新</h5>
                            <form className="mt-5">
                                <div className="rgst_select">
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" onChange={this.handleChangeImage} id="customFile2" />
                                            <label className="custom-file-label" htmlFor="customFile2"
                                                data-browse="参照">{this.state.label}</label>
                                        </div>
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-outline-secondary reset_tsv" onClick={this.cancelFile}>取消</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card rgst_explan mt-5">
                                    <div className="card-body">
                                        <h6 className="card-title">tsv書式
                                        <small className="text-muted">タブ区切り</small>
                                        </h6>
                                        <p className="card-text text-muted">
                                        [uuid] [ISBN][版][刷] [出版物名] [発行日] [発売日] [国種（国内：0 |海外：1）] [書籍種別（QR本・販売本| QR本|販売本|無料本）] [紙面バージョン] [iOSアイテムコード] [Androidアイテムコード] [入手期限開始日] [ 入手期限終了日] [閲覧期限] [書籍説明文] [エリアコード] [シリーズコード]
                                        </p>
                                    </div>
                                </div>
                                <div className="rgst_btn_wrap mt-g">
                                    <button type="button" className="btn btn-light cancel"
                                        onClick={() => window.location.href = "/publishdatas/"}>キャンセル
                                </button>

                                    <button type="button" className="btn btn-danger update" onClick={this.bulkRegistration}
                                    >保存
                                </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
export default RegisterUpdatePublicationBulk;