import React from "react";
import { useDrag, useDrop } from "react-dnd";

export const TrComponent = ({ children, className, ...rest }) => {
  return (
    <div className="rt-tr" role="row" {...rest}>
      {children}
    </div>
  );
};

export const TrComponentDnD = ({ children, className, onDrop, ...rest }) => {
  let index = -1;
  if (children[0].props.children.props !== undefined) {
    index = children[0].props.children.props.index;
  }
  const [{ isOver }, drop] = useDrop({
    accept: "Row",
    drop: (item) => onDrop(item, index),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  if (index < 0)
    return (
      <div className={"rt-tr " + className} role="row" {...rest}>
        {children}
      </div>
    );
  return (
    <div
      className="rt-tr"
      ref={drop}
      style={{
        backgroundColor: isOver ? "gainsboro" : "",
        borderBottom: isOver ? "2px solid red" : "",
      }}
    >
      <TrChildDnD children={children} index={index} {...rest} />
    </div>
  );
};
export const TrChildDnD = ({ children, index, ...rest }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id: index, type: "Row" },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <div className="rt-tr" ref={drag}>
      <div
        className="rt-tr"
        style={{ opacity: isDragging ? 0.7 : 1 }}
        role="row"
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

