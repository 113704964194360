/* eslint-disable */
import React, { Component } from "react";
import { 
    browsePublicationDataAPI, 
    updateBrowsePublicationAPI,
    API_IMAGE_URL,
} from "../ApiIntegration"
import { NotificationManager } from 'react-notifications';
import queryString from 'query-string';
import Loader from 'react-loader-spinner';


class RegisterUpdatePublicationBrows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            id: '',
            browsingPage: '',
            isbnEdition: '',
            thumbnails: [],
            totalPage: 0,
            startPage: '',
            endPage: '',
            selects: [],
            range: [],
        }
    }

    componentDidMount() {
        document.title = "出版物データ登録・更新"

        let ids = queryString.parse(this.props.location.search).id
        browsePublicationDataAPI({ id: ids }, apiResponse => {
            if (apiResponse.response.data.success == true) {
                const browsingPage = apiResponse.response.data.data.browsing_page;
                
                let pages = [];
                if (browsingPage.includes('-')) {
                    let [start, end] = browsingPage.split('-')
                    start = parseInt(start);
                    end = parseInt(end);

                    for (;start <= end; start ++) {
                        pages.push(start.toString());
                    }
                } else {
                    pages = browsingPage.split(',')
                }

                const thumbnails = []
                const startPage = apiResponse.response.data.data.start_page;
                const endPage = apiResponse.response.data.data.end_page;

                for (let i = startPage; i <= endPage; i++) {
                    thumbnails.push(String(i));
                }

                this.setState({
                    loading: false,
                    id: apiResponse.response.data.data.id,
                    isbnEdition: apiResponse.response.data.data.isbn_edition,
                    browsingPage: browsingPage,
                    totalPage: apiResponse.response.data.data.total_page,
                    startPage: pages[0],
                    selects: pages,
                    endPage: pages[pages.length - 1],
                    thumbnails: thumbnails,
                    range: [startPage, endPage]
                })
            } else {
                const err = apiResponse.response.data.error;
                Object.keys(err).forEach(v => {
                    if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
                });
                this.setState({
                    loading: false,
                })
            }
        });
    }

    savePublicationData = (save) => {
        const { id, selects } = this.state;
        var browsePage = selects.join(',');
        let payload = {
            id: id, browsing_page: browsePage, save: save
        }

        updateBrowsePublicationAPI(payload, ({ response }) => {
            if (response.data.success == true) {
                localStorage.setItem("totalPage", selects.length)
                window.location.href = "/publishdatas_edit/" + this.state.id + "/";
                if (save) NotificationManager.success(1, response.data.message, "Signup Success");
            } else {
                const err = response.data.error;
                Object.keys(err).forEach(v => {
                    if (err[v]) NotificationManager.error(err[v], `${v}`, 4000);
                });
            }
        });
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleSelect = (thumbnail) => {
        const selects = this.state.selects;
        const index = selects.indexOf(thumbnail);
        if (index > -1) {
            selects.splice(index, 1);
        } else {
            selects.push(thumbnail);
        }

        selects.sort(function(a, b){return a - b});
        this.setState({ selects });
    }
    multiSelect = () => {
        let { startPage, endPage, range } = this.state;
        
        startPage = parseInt(startPage);
        endPage = parseInt(endPage);

        startPage = startPage < range[0] ? range[0] : startPage;
        endPage = endPage > range[1] ? range[1] : endPage;

        let selects = [];        
        for (let i =  startPage; i <= endPage; i++) {
            selects.push(String(i));
        }
        this.setState({ selects, startPage: startPage.toString(), endPage: endPage.toString() });
    }
    render() {
        return (
            <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
            
                <nav aria-label="パンくずリスト">
                    <ol className="breadcrumb bg-white">
                        <li className="breadcrumb-item">出版物データ登録・更新</li>
                        <li className="breadcrumb-item">編集</li>
                        <li className="breadcrumb-item">試し読み設定</li>
                    </ol>
                </nav>

                <div className="row justify-content-center">
                    <div className="col-7">
                        <div className="d-flex justify-content-center">
                            <div className="text-right">
                                <input type="number" className="form-control d-inline-block w-50 mr-2" name="startPage" 
                                    value={this.state.startPage || ''} onChange={this.handleChange} placeholder="開始"/>
                                    ページ
                        </div>
                            <div className="px-3 mt-1 text-center">〜</div>
                            <div>
                                <input type="number" className="form-control d-inline-block w-50 mr-2" name="endPage" 
                                    value={this.state.endPage || ''} onChange={this.handleChange} placeholder="終了"/>
                                    ページ
                        </div>
                            <button type="button" className="btn btn-primary px-4 ml-0" onClick={this.multiSelect}>選択
                        </button>
                        </div>
                    </div>
                </div>
                {this.state.loading && <div style={{width: 80, margin: '20px auto'}}>
                    <Loader type="Oval" color="#00BFFF" height={80} width={80}/>
                </div>}
                <form className={`recode_edit_form publishdata_form ${this.state.loading && "none"}`}>
                    <div className="image_wrap">
                        <ul className="thumbnails image_picker_selector">
                            {this.state.thumbnails.map(thumbnail => 
                                <li key={thumbnail} onClick={this.handleSelect.bind(this, thumbnail)}>
                                    <div className={`thumbnail ${this.state.selects.indexOf(thumbnail) > -1 && "selected"}`} >
                                        <img className="image_picker_image" 
                                            src={API_IMAGE_URL.concat(`?isbn_edition=${this.state.isbnEdition}&id=${thumbnail}`)} />
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </form>
                <div className="rgst_btn_wrap">
                    <button type="button" className="btn btn-light"
                        onClick={() => this.savePublicationData(false)}>キャンセル
                    </button>
                    <button type="button" className="btn btn-danger" onClick={() => this.savePublicationData(true)}>
                        保存
                    </button>
                </div>
                <br /><br />

            </main>
        );
    }
}
export default RegisterUpdatePublicationBrows;
