/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { reteriveOneTimeUrlAPI } from "../ApiIntegration";


class OneTimeUrlConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          id: "",
          downloadDeadline: "",
          epubCover: "",
          isbnEdition: "",
          publicationDate: "",
          seriesName: "",
          title: "",
          limitedCount: ""
        };
      }
      componentDidMount() {
        document.title = "ワンタイムURLスキーム発行";
        reteriveOneTimeUrlAPI(
          { id: this.props.match.params.id },
          (apiResponse) => {
            if (apiResponse.response.data.success == true) {
              let data_response = apiResponse.response.data.data[0]
              this.setState({
                id: data_response.id,
                downloadDeadline: data_response.download_deadline,
                epubCover: data_response.epub_cover,
                isbnEdition: data_response.isbn_edition,
                publicationDate: data_response.publication_date,
                seriesName: data_response.series_name,
                title: data_response.title,
                limitedCount: data_response.limited_count
              });
            }
          }
        );
      }
      copyUrl = () => {
        const el = document.createElement("textarea");
        var copyText = document.getElementById("sharableurl");
        el.value =
          this.state.seriesName + "/" + this.state.title + "\n" + copyText.value;
        
        document.body.appendChild(el);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(el);

        copyText.select();
        copyText.setSelectionRange(0, 99999)
      }
    
      render() {
        return (
          <main role="main" class="col-md-10 ml-sm-auto col-lg-10 px-4">
            <nav aria-label="パンくずリスト">
              <ol class="breadcrumb bg-white">
                <li class="breadcrumb-item">ワンタイムURLスキーム発行</li>
                <li class="breadcrumb-item">一時コード発行</li>
              </ol>
            </nav>
            <form class="recode_edit_form">
              <div class="form-group row">
                <div class="col-5">
                  <div class="form-group">
                    <p class="label">選択シリーズ名・書籍名</p>
                    <p class="p_item">{this.state.seriesName}/ {this.state.title}</p>
                  </div>
                  <div class="form-group">
                    <p class="label">出版日</p>
                    <p class="p_item">{this.state.publicationDate ? this.state.publicationDate : ""}</p>
                  </div>
                  <div class="form-group">
                    <p class="label">ダウンロード期限</p>
                    <p class="p_item">{this.state.downloadDeadline ? this.state.downloadDeadline : ""}</p>
                  </div>
                </div>
                <div class="col-7">
                  <div class="onetime_img">
                    {this.state.epubCover ?
                      <img src={this.state.epubCover} />
                      :
                      <img src={require("../../assets/img/sample3.png")} />
                    }
                  </div>
                </div>
              </div>
              <div class="form-group row mt-5">
                <div class="col-12">
                  <div class="onetime_book_data">
                    <p class="label">有効期限</p>
                    <p class="p_item">{localStorage.getItem('endDate')}</p>
                  </div>
                  <div class="onetime_book_data">
                    <p class="label">回数制限</p>
                    <p class="p_item">{this.state.limitedCount === 0 ? "無制限" : this.state.limitedCount}</p>
    
                  </div>
                  <div class="onetime_book_data">
                    <p class="label">ワンタイムURL</p>
                    {/* <p
                      id="sharableurl"
                      class="p_item border border-secondary rounded bg-light p-2"
                    >
                      {localStorage.getItem('url')}
                    </p> */}
                    <input type="text" style={{ width: "800px", height: "50px" }} value={localStorage.getItem('url')} id="sharableurl" readOnly class="p_item border border-secondary rounded bg-light p-2" />
                    <br />  <br />
                    {/* <p class="p_item">
                      <button type="button" id="url_copy" class="btn btn-info" >  
                        コピー
                      </button>
                    </p> */}
    
                    <button type="button" class="copy_btn_wrap btn btn-info" onClick={this.copyUrl}>
                    
                    コピー
                    
                    </button>
                  </div>
                </div>
              </div>
              <div class="rgst_btn_wrap justify-content-center">
                <button
                  type="button"
                  class="btn btn-light cancel"
                  onClick={() => history.go(-1)}
                >
                  戻る
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={() => (window.location.href = "/onetimeurl/")}
                >
                  検索画面へ
                </button>
              </div>
            </form>
          </main>
        );
      }
    }
export default OneTimeUrlConfirm;
