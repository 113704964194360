/* eslint-disable */
import React, { Component } from "react";
import Pagination from "../../Utils/DatatablePagination";

import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Link
} from 'react-router-dom';
import { listPublicationDataAPI, bookEncryptionAPI } from "../ApiIntegration";
import Loader from 'react-loader-spinner';
import { NotificationManager } from 'react-notifications';


class RegisterUpdatePublicationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataLength: "",
      search: "",
      page: 1,
      pages: 2,
      loading: false,
      loader: false,
      isbn: "",
    };
  }

  onPageChange = e => {
    this.setState({ page: e + 1 });
    this.listPublicationData(e + 1, this.state.search);
  }

  handlleChange = e => {
    this.setState({ search: e.target.value })
  }

  componentDidMount() {
    document.title = "出版物データ登録・更新"
    this.listPublicationData(this.state.page, this.state.search);

  }
  listPublicationData = (page, search) => {
    this.setState({ loading: true });
    listPublicationDataAPI({ search: search, page_no: page }, apiResponse => {
      if (apiResponse.response.data.status == true) {
        this.setState({
          data: apiResponse.response.data.data,
          pages: apiResponse.response.data.page.total_pages,
          page: this.state.page,
        },
          () => this.setState({ loading: false })
        )
      };
    });
  };
  searching = () => {
    this.listPublicationData(this.state.page, this.state.search.trim());
  }
  reset = () => {
    this.setState({ search: "" })
    this.listPublicationData(this.state.page, "");

  }
  encryption = (isbn) => {
    this.setState({ loader: true, isbn: isbn })
    bookEncryptionAPI({ isbn_edition: isbn }, apiResponse => {
      console.log("kkkkkkkkkkkkkkkkk", apiResponse)
      if (apiResponse.response.data.success == true) {
        this.setState({ loader: false })
        NotificationManager.success(apiResponse.response.data.message, "", 4000);

      }
      else {
        const err = apiResponse.response.data.errors;
        NotificationManager.warning(err, ` error!`, 4000);
        this.setState({ loader: false })
      }
    });
  }
  onSubmit = e => {
    e.preventDefault();
    this.searching();
  }
  render() {
    return (
      <main role="main" className="publishdata col-md-10 ml-sm-auto col-lg-10 px-4">
        <nav aria-label="パンくずリスト">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item">出版物データ登録・更新</li>
          </ol>
        </nav>
        <div className="alert alert-danger alert-dismissible fade show none" role="alert">
          保存に失敗しました。
            <button type="button" className="close" data-dismiss="alert" aria-label="閉じる">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="alert alert-success alert-dismissible fade show none" role="alert">
          保存に成功しました。
            <button type="button" className="close" data-dismiss="alert" aria-label="閉じる">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <div className="row">
          <div className="col-3">
            <p>
              <button type="button" className="btn btn-outline-primary list_search_btn" data-toggle="collapse"
                data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                検索エリア非表示
                    </button>
            </p>
          </div>
        </div>
        <div className="collapse show list_search_box" id="collapseExample">
          <div className="card card-body px-3 py-2" style={{ marginTop: "12px" }}>
            <form className="list_search" onSubmit={this.onSubmit} >
              <div className="form-row mb-3">
                <div className="form-group col-6">
                  <label>書籍名・ISBN版刷</label>
                  <input type="text" className="form-control mr-2" name="search" onChange={this.handlleChange}
                    placeholder="書籍名またはISBN版刷を入力" id="search_name" value={this.state.search} />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <button type="button" className="btn btn-light mr-1" onClick={this.reset} >クリア</button>
                  <button type="submit" className="btn btn-secondary ml-1">検索</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col list_btn_wrap">
          {/* <button type="button" className="btn btn-warning" onClick={() => window.location.href = "/publishdatas_bulk_upload/"}>
                        一括アップロード
                        </button> */}
          <button type="button" className="btn btn-primary" onClick={() => window.location.href = "/publishdatas_add"}>
            新規登録
                        </button>

                    &nbsp;&nbsp;
                    <Link to="/publishdatas_regist">
            <button type="button" className="btn btn-success">
              一括更新
                    </button>
          </Link>
        </div>
        <div className="default_list_wrap mb-5">
          <ReactTable
            data={this.state.data}
            pages={this.state.pages}
            loading={this.state.loading}
            manual
            page={this.state.page - 1}
            defaultPageSize={20}
            showPageJump={false}
            showPageSizeOptions={false}
            PaginationComponent={Pagination}
            sortable={false}
            onPageChange={this.onPageChange}
            minRows={2}
            className="table thead th"
            columns={[
              {
                accessor: "epub_cover",
                Header: () => <div className="text-center">表紙</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <p className="list_img table.default_list td">

                    {props.value ? <img className="rounded img-fluid" src={props.value} />
                      :
                      <img className="rounded img-fluid" src={require("../../assets/img/sample1.png")} />
                    }
                  </p>

                )
              },
              {
                accessor: "isbn_edition",
                Header: () => <div className="text-center">ISBN版刷</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <p className="table.default_list td">

                    {props.value ? props.value : ""}
                  </p>
                )
              },
              {
                accessor: "title",
                Header: () => <div className="text-center">書籍名</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <Link to={"/publishdatas_edit/" + props.original.id + "/"}>
                    <p className="table.default_list td text-wrap">
                      {props.value ? props.value : ""}
                    </p>
                  </Link>
                )
              },

              {
                accessor: "book_type",
                Header: () => <div className="text-center">書籍種別</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <p className="table.default_list td" style={{ textAlign: "center" }}>
                    {props.value ? props.value : ""}
                  </p>
                )
              },
              {
                accessor: "release_date",
                Header: () => <div className="text-center">発売日</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <p className="table.default_list td" style={{ textAlign: "center" }}>
                    {props.value ? props.value : ""}
                  </p>
                )
              },

              {
                accessor: "created",
                Header: () => <div className="text-center">登録日時</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <p className="table.default_list td" style={{ textAlign: "center" }}>
                    {props.value ? props.value.slice(0, 10) : ""} {<br />}
                    {props.value ? props.value.slice(11, 16) : ""}
                  </p>
                )
              },
              {
                accessor: "modified",
                Header: () => <div className="text-center">更新日時</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <p className="table.default_list td" style={{ textAlign: "center" }}>
                    {props.value ? props.value.slice(0, 10) : ""} {<br />}
                    {props.value ? props.value.slice(11, 16) : ""}
                  </p>
                )
              },
              {
                accessor: "download_url",
                Header: () => <div className="text-center">紙面変換</div>,
                headerStyle: { fontWeight: "bold" },
                Cell: props => (
                  <p className="table.default_list td" style={{ textAlign: "center" }}>
                    {props.value ? "◯" : "×"}
                  </p>
                )
              },
              {
                Header: "",
                accessor: "isbn_edition",
                Cell: props => (
                  <p className="table.default_list td">
                    {props.value == this.state.isbn && this.state.loader ?
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={30}
                        width={30}
                        style={{ marginLeft: "22px", paddingTop: "22px" }}
                      />
                      :
                      <button type="button" className="btn btn-danger" onClick={() => this.encryption(props.value)} >データ変換</button>
                    }
                  </p>
                )
              },


            ]}
          />

        </div>

      </main>
    );
  }
}
export default RegisterUpdatePublicationCard;