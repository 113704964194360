/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Row, } from "reactstrap";

import {
  Link
} from 'react-router-dom';
import { reterivePublicationAreaAPI, addPublicationAreaAPI } from "../ApiIntegration";
import { NotificationManager } from 'react-notifications';

class PublishAreasEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      reflaction: "",
      registerDate: "",
      lastModified: "",
      areaCode: "",
      areaName: "",
      countryType: "",
      status: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleStatus = (e) => {

    this.setState({ status: !this.state.status })
  }
  componentDidMount() {
    document.title = "出版物エリアマスタ"
    reterivePublicationAreaAPI({ id: this.props.match.params.id }, apiResponse => {

      if (apiResponse.response.data.success == true) {
        this.setState({
          id: apiResponse.response.data.data[0].id,
          countryType: apiResponse.response.data.data[0].oversea,
          reflaction: apiResponse.response.data.data[0].draft,
          registerDate: apiResponse.response.data.data[0].registration,
          lastModified: apiResponse.response.data.data[0].modified,
          areaCode: apiResponse.response.data.data[0].area_code,
          areaName: apiResponse.response.data.data[0].area_name,
          status: apiResponse.response.data.data[0].status,
        });
      }
    });

  }

  editHandler = () => {
    const { areaCode, areaName, countryType, status } = this.state;
    addPublicationAreaAPI({
      id: this.state.id,
      area_name: areaName, area_code: areaCode,
      oversea: countryType, status: status
    }, ({ response }) => {
      if (response.data.success == true) {
        NotificationManager.success(response.data.message, "Pulication Area Success", 3000)
        window.location.href = "/publishareas/";
      }
      else {
        const err = response.data.error;
        Object.keys(err).forEach(v => {
          if (err[v]) NotificationManager.error(err[v], `${v} error!`, 4000);
        });
      }
    });
  };

  render() {
    return (
      <main role="main" className="col-md-10 ml-sm-auto col-lg-10 px-4">
        <nav aria-label="パンくずリスト">
          <ol className="breadcrumb bg-white">
            <li className="breadcrumb-item">出版物エリアマスタ</li>
            <li className="breadcrumb-item">編集</li>
          </ol>
        </nav>

        <div className="modal fade" id="editmodeConfirm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="閉じる">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>編集を破棄して一覧に戻りますか？</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal"
                  onClick={() => window.location.href = "/publishareas/"}>はい</button>
              </div>
            </div>
          </div>
        </div>

        <form className="recode_edit_form">
          <div className="form-group row border-bottom">
            <div className="col-3">
              <p className="label">ID</p>
              <p className="p_item">{this.state.id}</p>
            </div>
            <div className="col-3">
              <p className="label">登録日時</p>
              <p className="p_item">{this.state.registerDate}</p>
            </div>
            <div className="col-3">
              <p className="label">最終更新日時</p>
              <p className="p_item">{this.state.lastModified ? this.state.lastModified : ""}</p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-3">
              <label>エリアコード</label><span className="required">※</span>
              <input type="text" className="form-control" placeholder="エリアコード" onChange={this.handleChange} name="areaCode" value={this.state.areaCode} disabled />
              <span></span>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label>エリア名</label><span className="required">※</span>
              <input type="text" className="form-control" placeholder="エリア名" onChange={this.handleChange} name="areaName" value={this.state.areaName} />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <p className="label">国種<span className="required">※</span></p>
              <div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioInline1" name="countryType"
                    className="custom-control-input" onChange={this.handleChange} value="0" checked={this.state.countryType == "0" ? true : false} />
                  <label className="custom-control-label" htmlFor="customRadioInline1">国内</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioInline2" name="countryType" onChange={this.handleChange} value="1" checked={this.state.countryType == "1" ? true : false}
                    className="custom-control-input" />
                  <label className="custom-control-label" htmlFor="customRadioInline2">海外</label>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <p className="label">無効／有効<span className="required">※</span></p>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.status} onChange={this.handleStatus} />
                <label className="custom-control-label" htmlFor="customSwitch1"></label>
              </div>
            </div>
          </div>
          <div className="rgst_btn_wrap">
            <button type="button" className="btn btn-light" data-toggle="modal" data-target="#editmodeConfirm">キャンセル
            </button>
            <button type="button" className="btn btn-danger" onClick={this.editHandler}>保存</button>
          </div>
        </form>
      </main>
    );
  }
}
export default PublishAreasEdit;
